<template>
    <div class="col-sm-12">
        <h4>
            Customize Product Update items
            <span class="badge rounded-pill bg-secondary">{{
                data.total
            }}</span>
        </h4>
        <EmbedCode className="wbnz_product_update" widgetName="product-updates" />

        <form @submit.prevent="search(event)">
            <div class="input-group mb-3 float-end me-2" style="width: 300px">
                <input
                    v-model="search_keyword"
                    type="text"
                    class="form-control"
                    placeholder="Type any word..."
                    aria-label="Search"
                    aria-describedby="button-addon2"
                    v-on:keyup="detectEmptySearchBox(event)"
                />
                <button
                    class="btn btn-secondary"
                    type="submit"
                    id="button-addon2"
                >
                    Search
                </button>
            </div>
        </form>
        <div class="mt-5">
            <div class="row w-100">
                <Sidebar
                    :parentRef="$parent.$refs.readComponent"
                    :parentRefs="$parent.$refs"
                    ref="sidebar"
                />
                <div class="col-md-8">
                    <div id="preview"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
    .w-30 {
        width: 30% !important;
    }
</style>

<script>

import EmbedCode from "../../components/EmbedCode.vue";
import Sidebar from "./components/Sidebar";

export default {
    components: {
        Sidebar,
        EmbedCode
    },
    data() {
        return {
            token: this.$store?.getters?.logged_in_user?.data?.token ?? '',
            loading: true,
            data: "",
            id: "",
            name: "",
            desciption: "",
            edit_modal: null,
            delete_modal: null,
            search_keyword: ""
        };
    },
    methods: {
        async search(event, search_keyword) {
            const search_url =
                process.env.VUE_APP_API_URL +
                `product-update-items/${this.$route.params.widget_id}/` +
                search_keyword;

            this.read(event, search_url);
        },

        async detectEmptySearchBox(search_keyword) {
            if (!search_keyword) {
                this.read();
            }
        },

        async read(event, url) {
            if (event) {
                event.target.disabled = true;
            }

            // api request
            if (!url) {
                url = process.env.VUE_APP_API_URL + `product-update-items/${this.$route.params.widget_id}`;
            }

            const res = await fetch(url, {
                 headers: new Headers({
                     Authorization:
                         "Bearer " + this.token,
                 }),
             });

            if (this.data && this.data.links) {
                this.data.links = "";
            }
            this.data = await res.json();
            this.loading = false;
            this.$refs.sidebar.preview();

            if (event) {
                event.target.disabled = false;
            }
        },
        publish_date_formatted(date) {
            const dateObject = new Date(date);
            const options = {
                year: 'numeric',
                month: 'long',
                day: 'numeric'
            };
            return dateObject.toLocaleString('en-US', options);
        },
    },
};
</script>

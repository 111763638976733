<template>
    <nav class="navbar navbar-expand-lg navbar-light bg-light mb-5">
        <div class="container-fluid">
            <a class="navbar-brand" href="#">
                <img width="200" src="https://webynize.com/app/logo.svg" alt="logo" class="img-fluid">
            </a>

            <button
                class="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarNav"
                aria-controls="navbarNav"
                aria-expanded="false"
                aria-label="Toggle navigation"
            >
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarNav">
                <span v-if="is_logged_in" class="w-100">
                    <ul class="navbar-nav float-start">
                        <li class="nav-item">
                            <router-link to="/widgets/" class="nav-link active">My widgets</router-link>
                        </li>
                        <li class="nav-item">
                            <a href="#" class="nav-link active" @click.prevent="openCrispChat">Support</a>
                        </li>
                    </ul>

                    <ul class="navbar-nav float-end d-flex align-items-end gap-3">
                        <DropdownMenu>
                            <template v-slot:button>
                                <router-link
                                    to="/create-widget/"
                                    :class="btnClass"
                                    class="btn btn-primary btn-md float-end text-uppercase d-flex align-items-center"
                                >
                                    <svg class="me-1" width="16" height="16" fill="currentColor" aria-hidden="true" focusable="false" data-icon="plus" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg="">
                                        <path fill="currentColor" d="M432 256c0 17.69-14.33 32.01-32 32.01H256v144c0 17.69-14.33 31.99-32 31.99s-32-14.3-32-31.99v-144H48c-17.67 0-32-14.32-32-32.01s14.33-31.99 32-31.99H192v-144c0-17.69 14.33-32.01 32-32.01s32 14.32 32 32.01v144h144C417.7 224 432 238.3 432 256z"></path>
                                    </svg>
                                    Create Widget
                                </router-link>
                            </template>

                            <template v-slot:menu>
                                <li v-for="widget_type in widget_types.slice(0, 4)" :key="widget_type.id">
                                    <router-link
                                        :to="{ path: '/create-widget', query: { widget_type: widget_type.id } }"
                                        >
                                        {{ widget_type.name }}
                                    </router-link>
                                </li>
                                <li v-if="widget_types.length > 4">
                                    <router-link
                                        to="/explore-more-widgets"
                                        class="more-link"
                                    >
                                        More {{ widget_types.length - 4 }}+
                                    </router-link>
                                </li>
                            </template>
                        </DropdownMenu>

                        <DropdownMenu>
                            <template v-slot:button>
                                <button class="user-dropdown-button">
                                    <svg  version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                        <path d="M12 14.016q2.531 0 5.273 1.102t2.742 2.883v2.016h-16.031v-2.016q0-1.781 2.742-2.883t5.273-1.102zM12 12q-1.641 0-2.813-1.172t-1.172-2.813 1.172-2.836 2.813-1.195 2.813 1.195 1.172 2.836-1.172 2.813-2.813 1.172z"></path>
                                    </svg>
                                </button>
                            </template>

                            <template v-slot:menu>
                                <li v-if="role === 'admin'">
                                    <router-link to="/admin/users">Admin</router-link>
                                </li>
                                <li>
                                    <router-link to="/account/profile">Account Details</router-link>
                                </li>
                                <li>
                                    <a href="#" @click="logout">Logout</a>
                                </li>
                            </template>
                        </DropdownMenu>
                    </ul>
                </span>

                <span v-else class="w-100">
                    <ul class="navbar-nav float-end">
                        <li class="nav-item">
                            <router-link to="/login/" class="nav-link">Login</router-link>
                        </li>
                    </ul>
                </span>
            </div>
        </div>
    </nav>
</template>

<script>
import DropdownMenu from "./DropdownMenu";
import store from "../store";

export default {
    data() {
        return {
            widget_types: [],
        };
    },
    components: {
        DropdownMenu,
    },
    computed: {
        is_logged_in() {
            return this.$store.getters.is_authenticated;
        },
        role() {
            return this.$store.getters.logged_in_user.data.user.access_level;
        },
        btnClass() {
        if (this.$route.path.includes('update-widget')) {
            return 'btn-create-widget';
        }
        return '';
        },
    },
    methods: {
        async logout() {
            await this.$store.dispatch("logoutAuth");
            this.$router.push("/login/");
        },
        openCrispChat() {
            if (window.$crisp) {
                window.$crisp.push(["do", "chat:open"]);
            } else {
                console.warn('Crisp is not initialized');
            }
        },
        async readWidgetTypes(event, url) {
            if (event) {
                event.target.disabled = true;
            }

            if (!url) {
                url = process.env.VUE_APP_API_URL + "widget-types/status/1";
            }

            const res = await fetch(url, {
                headers: new Headers({
                    Authorization:
                        "Bearer " + store.getters.logged_in_user.data.token,
                }),
            });

            this.widget_types = await res.json();

            if (event) {
                event.target.disabled = false;
            }
        },
    },
    mounted() {
        this.readWidgetTypes();
    },
};
</script>

<style scoped>
.navbar a:hover {
    cursor: pointer;
}

.user-dropdown-button {
    background-color: #F0EFEB;
    border: none;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.2s ease;
}

.user-dropdown-button:hover {
    background-color: #e0ded9;
}

.more-link {
    display: inline-block;
    color: #007bff;
    font-weight: bold;
    transition: color 0.3s;
}
.btn-create-widget {
    background: transparent;
    color: #292D32;
    border-color: transparent;
}

</style>

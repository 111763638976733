<!-- DropdownMenu.vue -->
<template>
    <li class="custom-dropdown" @mouseover="openDropdown" @mouseleave="closeDropdown" @click="toggleDropdown">
        <slot name="button"></slot>

        <ul class="custom-dropdown-menu" v-show="dropdownOpen">
            <slot name="menu"></slot>
        </ul>
    </li>
</template>

<script>
export default {
    data() {
        return {
            dropdownOpen: false
        };
    },
    methods: {
        openDropdown() {
            this.dropdownOpen = true;
        },
        closeDropdown() {
            this.dropdownOpen = false;
        },
        toggleDropdown() {
            this.dropdownOpen = !this.dropdownOpen;
        }
    }
};
</script>

<style>
.custom-dropdown {
    position: relative;
    width: max-content;
}

.custom-dropdown li {
    padding: 0;
    cursor: pointer;
}

.custom-dropdown-menu {
    width: 100%;
    padding: 0;
    margin: 0;
    position: absolute;
    right: 0;
    top: 100%;
    list-style: none;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    font-weight: 600;
    animation: fadeInDown 0.3s ease forwards;
    z-index: 100;
}

@keyframes fadeInDown {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.custom-dropdown li a,
.custom-dropdown li button {
    font-size: 14px;
    width: 100%;
    display: inline-block;
    text-decoration: none;
    color: inherit;
    padding: 10px 15px;
    white-space: nowrap;
    transition: all 0.2s ease;
    border-radius: 0;
    color: rgba(0, 0, 0, 0.65);
}

.custom-dropdown li a:hover,
.custom-dropdown li button:hover {
    background-color: #d2f4ea;
    color: rgba(0, 0, 0, 0.85);
}

.custom-dropdown li :focus,
.custom-dropdown li a:active,
.custom-dropdown li button:focus,
.custom-dropdown li button:active {
    background-color: #cbe8d6;
    color: rgba(0, 0, 0, 0.85);
}

.custom-dropdown-menu {
    max-width: 250px;
    min-width: 150px;
    word-wrap: break-word;
}

</style>

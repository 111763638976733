<template>
    <VoteViewer ref="voteViewerComponent" />
    <Read ref="readComponent" />
</template>

<script>
import Read from "./Read";
import VoteViewer from "./VoteViewer.vue";

export default {
    components: {
        Read,
        VoteViewer
    }
}
</script>

<style scoped>
    body {
        background:red;
    }
</style>
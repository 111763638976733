<template>
    <button
        @click="showEmbedCode($route.params.widget_id)"
        type="button"
        class="btn btn-primary me-2 fs-5 d-flex align-items-center float-end"
    >
        <img 
            :src="getBuilderLogoUrl(selectedBuilder?.logo || 'img/cms/logo-106-63772837d096f.png')"
            class="builder-logo builder-logo-small" 
            alt="Logo"
        />
        Embed on {{ selectedBuilder?.cms || 'website' }}
    </button>

    <LoginMessage :userId="userId" :widgetId="$route.params.widget_id" />

    <div
        class="modal"
        ref="embedCodeModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                
                <div class="modal-content" v-if="!selectedBuilder">

                    <div class="modal-header">
                        <h5 class="modal-title">What is your website builder?</h5>
                        <button
                            type="button"
                            class="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></button>
                    </div>

                    <div v-if="isLoading">
                        <div class="loader"></div>
                    </div>

                    <div class="modal-body" v-else>
                        <p>Please select a website builder so we can help you embed {{ formatWidgetName(widgetName) }} on your website.</p>

                        <div class="mb-3">
                            <div class="input-group">
                                <input
                                    type="text"
                                    class="form-control"
                                    placeholder="Type to search your website builder..."
                                    v-model="searchKey"
                                    @input="filterBuilders"
                                    id="search_cms_key"
                                />
                                <span class="input-group-text">Search</span>
                            </div>
                        </div>

                        <div v-if="suggestedBuiders.length === 0" class="text-center">No results found.</div>

                        <div class="row" v-if="searchKey === '' && suggestedBuiders.length">
                            <div class="col-md-6 col-sm-12 mb-2" v-for="(builder, index) in suggestedBuiders" :key="index">
                                <button class="card hover-effect w-100" @click="selectBuilder(builder.name)" :disabled="isLoading">
                                    <div class="card-body py-1 px-3 d-flex align-items-center">
                                        <img :src="builder.logo" class="builder-logo" alt="Logo" />
                                        <span class="m-0">{{ builder.name }}</span>
                                    </div>
                                </button>
                            </div>
                        </div>
                        
                        <div class="row mt-3" v-if="searchKey === '' && !moreBuildersVisible">
                            <div class="col-12 text-center">
                                <div class="border rounded p-3 shadow-sm">
                                    <button @click="toggleMoreBuilders" class="btn btn-primary w-100">
                                        <strong>View more integrations</strong>
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div class="row" v-if="moreBuildersVisible">
                            <div class="col-12 mb-2"><b>All website builders</b></div>
                            <div class="col-md-6 col-sm-12 mb-2" v-for="(builder, index) in filteredBuilders" :key="index">
                                <button class="card hover-effect w-100" @click="selectBuilder(builder.cms)" :disabled="isLoading">
                                    <div class="card-body py-1 px-3 d-flex align-items-center">
                                        <img v-if="builder.logo" class="builder-logo" :src="getBuilderLogoUrl(builder.logo)" alt="Logo" />
                                        <span class="m-0">{{ builder.cms }}</span>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="modal-content" v-if="selectedBuilder">
                    <div class="modal-header">
                        <h5 class="modal-title"></h5>
                        <img v-if="selectedBuilder.logo" class="img-fluid rounded-circle border" :src="getBuilderLogoUrl(selectedBuilder.logo)" alt="Logo" style="width: 40px; height: 40px; margin-right: 10px;" />
                        <button @click="resetSelection" class="btn btn-secondary">Choose website builder</button>
                        <button
                            type="button"
                            class="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></button>
                    </div>

                    <div class="modal-body">
                        <div class="instruction-container">
                            <h5>Embed {{ formatWidgetName(widgetName) }} on your {{ selectedBuilder.cms }} website, follow the steps below.</h5>
                            <div v-html="preInstructionParagraphs"></div>

                            <div class="instruction-container">
                                <ol>
                                    <li>
                                        <label>Select Embed Type:</label>
   

                                        <div class="position-relative">
                                            <select class='form-control mb-3' v-model="selectedEmbedType" @change="appendEmbedCode">
                                                <option value='js'>Javascript Code</option>
                                                <option value='iframe'>iFrame Code</option>
                                                <option value='link'>Link</option>
                                            </select>
                                            <span class="dropdown-icon position-absolute" style="top: 50%; right: 10px; transform: translateY(-50%);">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                                    <path d="M7.247 11.14l-4.796-5.481A.5.5 0 0 1 2.5 5h11a.5.5 0 0 1 .388.84l-4.796 5.48a.5.5 0 0 1-.776 0z"/>
                                                </svg>
                                            </span>
                                        </div>

                                        <div v-if="embed && selectedEmbedType === 'js'">
                                            <div>
                                                <label for="embedCode" class="form-label">JavaScript Embed Code</label>
                                                <textarea id="embedCode" class='form-control mb-3' readonly v-model="embed.jsCode"></textarea>
                                                <button class='btn btn-primary' @click="copyToClipboard(embed.jsCode)">{{ copyStatus }}</button>
                                            </div>
                                        </div>

                                        <div v-if="embed && selectedEmbedType === 'iframe'">
                                            <div class="row mb-3">
                                                <div class="col-12 col-md-6">
                                                    <label for="embedWidth" class="form-label">Width</label>
                                                    <div class="d-flex">
                                                        <input id="embedWidth" type="number" class='form-control me-2' v-model="iframeWidth" placeholder="Enter width" />
                                                        <select v-model="widthUnit" class='form-select'>
                                                            <option value="px">Pixels</option>
                                                            <option value="%">Percentage</option>
                                                        </select>
                                                    </div>
                                                </div>

                                                <div class="col-12 col-md-6">
                                                    <label for="embedHeight" class="form-label">Height</label>
                                                    <div class="d-flex">
                                                        <input id="embedHeight" type="number" class='form-control me-2' v-model="iframeHeight" placeholder="Enter height" />
                                                        <select v-model="heightUnit" class='form-select'>
                                                            <option value="px">Pixels</option>
                                                            <option value="%">Percentage</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>

                                            <textarea id="embedCode" class='form-control mb-3' readonly v-model="embed.iframeCode"></textarea>
                                            <button class='btn btn-primary' @click="copyToClipboard(embed.iframeCode)">{{ copyStatus }}</button>
                                        </div>

                                        <div v-if="embed && selectedEmbedType === 'link'">
                                            <div>
                                                <label for="embedCode" class="form-label">Link</label>
                                                <textarea id="embedCode" class='form-control mb-3' readonly v-model="embed.linkCode"></textarea>
                                                <button class='btn btn-primary' @click="copyToClipboard(embed.linkCode)">{{ copyStatus }}</button>
                                            </div>
                                        </div>
                                    </li>

                                    <template v-for="(instruction, index) in embedInstructionList" :key="index">
                                        <li v-html="instruction"></li>
                                    </template>
                                    <template v-for="(instruction, index) in embedInstructionParagraphs" :key="index">
                                        <p v-html="instruction"></p>
                                    </template>
                                </ol>
                            </div>

                            <div v-html="postInstructionParagraphs"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Modal } from "bootstrap";
import LoginMessage from "./LoginMessage.vue";

export default {
    components: {
        LoginMessage
    },
    props: {
        className: {
            type: String,
            default: ''
        },
        widgetName: {
            type: String,
            required: true
        },
        webynizeIdAttr: {
            type: String,
            default: 'data-webynize-id'
        }
    },
    data() {
        return {
            token: this.$store?.getters?.logged_in_user?.data?.token ?? '',
            user_id: this.$store?.getters?.logged_in_user?.data?.user?.id ?? 12,
            embed_code_modal: null,
            isLoading: false,
            widget_id: "",
            searchKey: '',
            data: null,
            allBuilders: [],
            filteredBuilders: [],
            suggestedBuiders: [],
            moreBuildersVisible: false,
            selectedBuilder: null,
            selectedEmbedType: 'js',
            sk_api_url: "https://api.sociablekit.com/",
            copyStatus: "Copy code",
            iframeWidth: 100,
            iframeHeight: 1000,
            widthUnit: '%',
            heightUnit: 'px',
            embed: null,
        }
    },
    watch: {
        iframeWidth() {
            this.updateEmbedCode();
        },
        iframeHeight() {
            this.updateEmbedCode();
        },
        widthUnit() {
            this.updateEmbedCode();
        },
        heightUnit() {
            this.updateEmbedCode();
        }
    },
    computed: {
        userId() {
            return this.data?.user_id;
        }
    },
    methods: {
        showEmbedCode() {
            this.embed_code_modal.show();
            this.updateEmbedCode();
        },
        updateEmbedCode() {
            this.embed = {
                jsCode: `<div class='${this.className}' ${this.webynizeIdAttr}='${this.widget_id}'></div><script src='${process.env.VUE_APP_WIDGET_URL}${this.widgetName}.js'></` + `script>`,
                iframeCode: `<iframe src='${process.env.VUE_APP_WIDGET_URL}${this.widgetName}/iframe/${this.widget_id}' frameborder='0' width='${this.iframeWidth}${this.widthUnit}' height='${this.iframeHeight}${this.heightUnit}'></iframe>`,
                linkCode: `${process.env.VUE_APP_WIDGET_URL}${this.widgetName}/iframe/${this.widget_id}`
            };
        },
        async fetchData() {
            if (this.allBuilders.length) {
                this.filteredBuilders = this.allBuilders;
                return;
            }

            const url = `${this.sk_api_url}api/cms/list`;
            try {
                const response = await fetch(url);
                const data = await response.json();
                this.allBuilders = data;
                this.filteredBuilders = this.allBuilders;
                this.getWidgetByUser();
                this.setupSuggestedCms();
            } catch (error) {
                console.error('Error fetching builders:', error);
            }

        },
        async getWidgetByUser() {
            try {
                const response = await fetch(`${process.env.VUE_APP_API_URL}widgets/${this.user_id}/${this.widget_id}?widget_id=${this.widget_id}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.token}`,
                    },
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch widget');
                }

                const data = await response.json();
                const builderData = this.allBuilders.find(builder => builder.id === data.data.cms_id);
                if (builderData) {
                    this.selectBuilder(builderData.cms, false);
                }
                this.data = data.data;
                return data;
            } catch (error) {
                console.error('Error fetching widget:', error);
            }
        },
        async updateWidgetCmsId() {
            try {
                const response = await fetch(`${process.env.VUE_APP_API_URL}widgets/update/cms/${this.widget_id}`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.token}`,
                    },
                    body: JSON.stringify({
                        cms_id: this.selectedBuilder.id,
                    }),
                });

                if (!response.ok) {
                    throw new Error('Failed to update widget CMS ID');
                }
            } catch (error) {
                console.error('Error updating widget CMS ID:', error);
            }
        },
        setupSuggestedCms() {
            const suggestedCmsNames = ["Website", "Squarespace", "Wix", "Shopify", "Webflow", "Wordpress", "Weebly", "Sharepoint", "HTML", "Google sites"];
            const suggestedCmsImages = [
                "https://api.sociablekit.com/img/cms/logo-106-63772837d096f.png", 
                "https://api.sociablekit.com/img/cms/logo-1-63693df4ab548.png", 
                "https://api.sociablekit.com/img/cms/logo-4-636d3b082e8c1.png", 
                "https://api.sociablekit.com/img/cms/logo-8-636d3bae135c2.png", 
                "https://api.sociablekit.com/img/cms/logo-6-636d3a6ef374a.png", 
                "https://api.sociablekit.com/img/cms/logo-2-636d3b7737da4.png", 
                "https://api.sociablekit.com/img/cms/logo-3-636d3ae46c102.png", 
                "https://api.sociablekit.com/img/cms/logo-114-636d3a1f6e5b3.png", 
                "https://api.sociablekit.com/img/cms/logo-14-636d400eec65e.png",
                "https://api.sociablekit.com/img/cms/logo-91-636eed92dfef9.png"
            ];

            this.suggestedBuiders = suggestedCmsNames.map((name, index) => ({
                name,
                logo: suggestedCmsImages[index],
            }));
        },
        filterBuilders() {
            if (this.searchKey) {
                this.filteredBuilders = this.allBuilders.filter(builder =>
                    builder.cms.toLowerCase().includes(this.searchKey.toLowerCase())
                );
                this.moreBuildersVisible = true;
            } else {
                this.filteredBuilders = this.allBuilders;
            }
        },
        toggleMoreBuilders() {
            this.moreBuildersVisible = !this.moreBuildersVisible;
        },
        async selectBuilder(name, updateCmsId = true) {
            this.isLoading = true;
            this.selectedBuilder = null;
            
            const url = `${this.sk_api_url}api/cms/read-by-name/${name}`;
            try {
                const response = await fetch(url);
                const data = await response.json();
                this.selectedBuilder = {
                    id: data.id,
                    cms: data.cms,
                    embedInstructions: data.embed_instructions.replace(/SociableKIT/g, 'Webynize'),
                    websiteUrl: data.website_url,
                    logo: data.logo
                };

                this.processEmbedInstructions(this.selectedBuilder.embedInstructions);

                if (updateCmsId) {
                    await this.updateWidgetCmsId();
                }
            } catch (error) {
                console.error('Error fetching CMS data:', error);
            } finally {
                this.isLoading = false;
            }
        },
        resetSelection() {
            this.selectedBuilder = null;
            this.searchKey = '';
            this.filterBuilders();
        },
        getBuilderLogoUrl(logo) {
            return `${this.sk_api_url}${logo}`;
        },
        processEmbedInstructions(instructions) {
            const preOlContent = instructions.match(/^(.*?)<ol>/s);
            const postOlContent = instructions.match(/<\/ol>(.*?)$/s);
            const cleanedInstructions = instructions.replace(/<ol>|<\/ol>/g, '');
            const listItems = cleanedInstructions.match(/<li>(.*?)<\/li>/g) || [];
            this.embedInstructionList = listItems.map(item => item.replace(/<\/?li>/g, '').trim());
            this.preInstructionParagraphs = preOlContent ? preOlContent[1].trim() : '';
            this.postInstructionParagraphs = postOlContent ? postOlContent[1].trim() : '';
        },
        copyToClipboard(text) {
            navigator.clipboard.writeText(text)
                .then(() => {
                    this.copyStatus = "Copied!";
                    setTimeout(() => {
                        this.copyStatus = "Copy code";
                    }, 2000);
                })
                .catch(err => {
                    console.error('Could not copy text: ', err);
                });
        },
        formatWidgetName(name) {
            return name.split('-')
                       .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                       .join(' ');
        }
    },
    mounted() {
        this.embed_code_modal = new Modal(this.$refs.embedCodeModal);
        this.widget_id = this.$route.params.widget_id;
        this.fetchData();
    },
}
</script>

<style>
.hover-effect {
    transition: all 0.3s ease;
}

.hover-effect:hover {
    cursor: pointer;
    background-color: #f8f9fa;
}

.hover-effect:hover img {
    transition: all 0.3s ease;
}

.hover-effect:hover img {
    transform: scale(1.08);
}

.instruction-container li {
    margin-top: 30px;
    margin-bottom: 20px;
}

.instruction-container img {
    border: thin solid #999;
    border-radius: 8px;
    margin-top: 10px;
    width: 100%;
}

.dropdown-icon svg {
    color: black;
}

.builder-logo {
    width: 40px;
    height: 40px;
    max-height: 40px;
    max-width: 40px;
    margin-right: 10px;
    border-radius: 50%;
    border: 1px solid #999;
}

.builder-logo-small {
    width: 20px;
    height: 20px;
    max-height: 20px;
    max-width: 20px;
}

</style>
<template>
    <div class="container p-5 pt-0">
        <h4>
            Onboarding checklist items
            <span class="badge rounded-pill bg-secondary">{{
                data.total
            }}</span>
        </h4>
        <EmbedCode className="onboardingchecklist" widgetName="onboardingchecklist" webynizeIdAttr="webynize_id" />

        <div class="row w-100">
            <Sidebar
                :parentRef="$parent.$refs.readComponent"
                :parentRefs="$parent.$refs"
                ref="sidebar"
            />
            <div class="col-md-8">
                <div id="preview">

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import EmbedCode from '../../components/EmbedCode.vue';
import Sidebar from './components/Sidebar.vue';

export default {
    components: {
        EmbedCode,
        Sidebar
    },
    data() {
        return {
            token: this.$store?.getters?.logged_in_user?.data?.token ?? '',
            loading: true,
            data: "",

            id: "",
            name: "",
            desciption: "",

            edit_modal: null,
            delete_modal: null,
        };
    },
    methods: {
        async search(event, search_keyword) {
            const search_url =
                process.env.VUE_APP_API_URL +
                `onboarding-checklist-items/${this.$route.params.widget_id}/` +
                search_keyword;

            this.read(event, search_url);
        },

        async detectEmptySearchBox(search_keyword) {
            if (!search_keyword) {
                this.read();
            }
        },

        async read(event, url) {
            if (event) {
                event.target.disabled = true;
            }

            // api request
            if (!url) {
                url = process.env.VUE_APP_API_URL + `onboarding-checklist-items/${this.$route.params.widget_id}`;
            }

            const res = await fetch(url, {
                 headers: new Headers({
                     Authorization:
                         "Bearer " + this.token,
                 }),
             });

            if (this.data && this.data.links) {
                this.data.links = "";
            }

            this.data = await res.json();
            this.loading = false;
            this.$refs.sidebar.preview();

            if (event) {
                event.target.disabled = false;
            }
        },
    },
};
</script>

<template>
    <div id="widget-customizer" class="col-md-4 widget-customizer">
        <div class="d-flex align-items-start">
            <div class="nav flex-column nav-pills me-3" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                <li class="nav-link active" id="v-pills-basic-tab" data-bs-toggle="pill" data-bs-target="#v-pills-basic" type="button" role="tab" aria-controls="v-pills-basic" aria-selected="false">Basic</li>
                <li class="nav-link" id="v-pills-colors-tab" data-bs-toggle="pill" data-bs-target="#v-pills-colors" type="button" role="tab" aria-controls="v-pills-colors" aria-selected="false">Colors</li>
                <li class="nav-link" id="v-pills-font-tab" data-bs-toggle="pill" data-bs-target="#v-pills-font" type="button" role="tab" aria-controls="v-pills-font" aria-selected="false">Fonts</li>
                <li class="nav-link" id="v-pills-spacing-tab" data-bs-toggle="pill" data-bs-target="#v-pills-spacing" type="button" role="tab" aria-controls="v-pills-spacing" aria-selected="false">Spacings</li>
            </div>

            <form class="tab-content flex-fill" id="v-pills-tabContent" @input="handleInput">
                <div class="tab-pane fade show active" id="v-pills-basic" role="tabpanel" aria-labelledby="v-pills-basic-tab">
                    <div class="container p-0 mb-4">
                        <div class="form-grid">
                            <div class="form-group">
                                <label>Name</label>
                                <input type="text" class="form-control" v-model="widget_name" id="widget_name">
                            </div>
                            <div class="form-group form-grid-span-full">
                                <label>Banner Text</label>
                                <QuillEditor v-model="widget_settings.banner_text" theme="snow" @input="handleInput" />
                            </div>
                            <div class="form-group">
                                <label>Button text</label>
                                <input type="text" class="form-control" v-model="widget_settings.button_text" id="button_text">
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tab-pane fade" id="v-pills-colors" role="tabpanel" aria-labelledby="v-pills-colors-tab">
                    <div class="container p-0 mb-4">
                        <div class="form-grid">
                            <div class="form-group">
                                <label for="">Banner background color</label>
                                <div class="form-group">
                                    <input 
                                        v-model="widget_settings.banner_background_color" 
                                        type="text" 
                                        id="banner_background_color"
                                        class="form-control setting setting-color"
                                    >
                                    <PickrColorPicker v-model="widget_settings.banner_background_color" @input="handleInput" />
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="">Banner font color</label>
                                <div class="form-group">
                                    <input v-model="widget_settings.banner_font_color" type="text" id="qf-tb-bg-color" class="form-control setting setting-color">
                                    <PickrColorPicker v-model="widget_settings.banner_font_color" @input="handleInput" />
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="">Button background color</label>
                                <div class="form-group">
                                    <input v-model="widget_settings.button_background_color" type="text" id="button_background_color" class="form-control setting setting-color">
                                    <PickrColorPicker v-model="widget_settings.button_background_color" @input="handleInput" />
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="">Button text color</label>
                                <div class="form-group">
                                    <input v-model="widget_settings.button_font_color" type="text" id="button_font_color" class="form-control setting setting-color">
                                    <PickrColorPicker v-model="widget_settings.button_font_color" @input="handleInput" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tab-pane fade" id="v-pills-font" role="tabpanel" aria-labelledby="v-pills-font-tab">
                    <div class="container p-0 mb-4">
                        <div class="form-grid">
                            <div class="form-group">
                                <label for="">Banner font size</label>
                                <input v-model="widget_settings.banner_font_size" type="number" id="banner_font_size" class="form-control setting">
                            </div>
                            <div class="form-group">
                                <label for="">Button font size</label>
                                <input v-model="widget_settings.button_font_size" type="number" id="vote_now_font_size" class="form-control setting">
                            </div>
                        </div>
                    </div>
                </div>


                <div class="tab-pane fade" id="v-pills-spacing" role="tabpanel" aria-labelledby="v-pills-spacing-tab">
                    <div class="container p-0 mb-4">
                        <div class="form-grid">
                            <div class="form-group">
                                <label for="">Padding</label>
                                <input v-model="widget_settings.padding" type="number" id="padding" class="form-control setting me-2">
                            </div>
                        </div>
                    </div>
                </div>

                <div class="container p-0 mb-4">
                    <div class="form-grid">
                        <button type="button" class="btn btn-primary" @click="save($event)">
                            <span v-if="isSaving" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            <span v-if="!isSaving">Save changes</span>
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<style scoped>
    .widget-customizer{
        border: 1px solid #c6c6c6;
        border-radius: 8px;
        background: #000000;
        color:white;
        padding:20px 10px;
    }
    .widget-customizer .form-group{
        display:inline-flex;
    }

    .widget-customizer .nav-link{
        color: white;
        background: black;
        border-right: 1px solid #555 !important;
        border-radius:0px;
    }
    .widget-customizer .nav-link.active{
        color: white;
        background: black;
        border: 1px solid #555 !important;
        border-right: none !important;
        border-radius: 4px 0 0 4px;
    }
    .widget-customizer .container{
        display:grid;
    }
</style>

<script>
import axios from "axios";
import PickrColorPicker from "../../../../components/PickrColorPicker.vue";
import QuillEditor from "../../../../components/QuillEditor.vue";

export default {
    components: {
        PickrColorPicker,
        QuillEditor,
    },
    data() {
        return {
            token: this.$store?.getters?.logged_in_user?.data?.token ?? '',
            widget_url: process.env.VUE_APP_WIDGET_URL,
            widget_id: "",
            data: "",
            id: "",
            isSaving: false,
            widget_name: "",
            widget_settings: {
                widget_id: 0,
                banner_background_color: "#000000",
                banner_font_color: "#FFFFFF",
                banner_font_size: 16,
                banner_text: "We use cookies to improve your experience on our website.",
                button_background_color: "#21BA58",
                button_font_color: "#FFFFFF",
                button_font_size: 16,
                button_text: "Accept",
                padding: 20,
            }
        };
    },
    methods: {
        async read() {
            const widgetUrl = `${process.env.VUE_APP_API_URL}widgets/${this.$store.getters.logged_in_user.data.user.id}/${this.widget_id}`;
            const widgetSettingUrl = `${process.env.VUE_APP_API_URL}cookie-consents/readByWidgetId/${this.widget_id}`;
            
            const headers = new Headers({
                Authorization: "Bearer " + this.token,
            });

            const [widgetResponse, widgetSettingResponse] = await Promise.all([
                fetch(widgetUrl, { headers }),
                fetch(widgetSettingUrl, { headers }),
            ]);

            const widgetData = await widgetResponse.json();
            const widgetSettingData = await widgetSettingResponse.json();

            if (widgetData) {
                this.widget_name = widgetData.data.name;
            }

            if (widgetSettingData) {
                this.widget_settings = widgetSettingData;
            }
        },
        handleInput() {
            const event = new CustomEvent('previewDataUpdated', { 
                detail: { 
                    previewData: this.widget_settings 
                }
            });
            window.dispatchEvent(event);
        },
        async save(event){
            var __this = this;
            __this.isSaving = true;
            if (event) {
                event.target.disabled = false;
            }
            var data = __this.widget_settings;
            data.widget_id = __this.widget_id;

            try {
                const response = await axios.post(
                    process.env.VUE_APP_API_URL + `cookie-consents/save/${__this.widget_id}`,
                    data,
                    {
                        headers: {
                            Authorization: "Bearer " + this.token,
                        },
                    }
                );

                if (response.data.id) {
                    await axios.put(
                        process.env.VUE_APP_API_URL + `widgets/${this.widget_id}`,
                        { name: __this.widget_name },
                        {
                            headers: {
                                Authorization: "Bearer " + this.token,
                            },
                        }
                    );
                }

                await this.read();
            } catch (error) {
                console.error('Error saving widget settings or updating name:', error);
            } finally {
                event.target.disabled = false;
                __this.isSaving = false;
                __this.preview();
            }
        },
        allPages() {

            if (event.target.checked) {
                this.widget_settings.pages = "";
            }
        },
        preview (){
            let previewContainer = document.querySelector('[data-webynize-id="' + this.$route.params.widget_id + '"]');
            let socialProofScript = document.querySelector('[src="' + process.env.VUE_APP_WIDGET_URL + 'cookie-consent.js' + '"]');

            if (previewContainer) {
                previewContainer.remove();
            }

            if (socialProofScript) {
                socialProofScript.remove();
            }
            previewContainer = document.createElement("div");
            previewContainer.setAttribute("data-webynize-id", this.$route.params.widget_id);
            previewContainer.classList.add("wbnz-cookie-consent");
            socialProofScript = document.createElement("script");
            socialProofScript.src = process.env.VUE_APP_WIDGET_URL + `cookie-consent.js`;
            socialProofScript.defer = true;
            document.getElementById("preview").appendChild(previewContainer);
            document.getElementById("preview").appendChild(socialProofScript);

            socialProofScript.onload = function() {
                window.dispatchEvent(new Event('load'));
            };
        }
    },
    mounted(){
        var __this = this;
        __this.widget_id = this.$route.params.widget_id;
        __this.read();
        __this.preview();
    }
};
</script>
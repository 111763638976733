<template>
    <div v-if="shouldShowMessage" class="position-fixed top-0 end-0 p-3 custom-z-index" role="alert">
        <div class="alert alert-primary" role="alert">
            To save this widget in your own account, please 
            <router-link :to="{ name: 'Signup', query: { widget_id: widgetId } }" class="alert-link">sign up</router-link> 
            or 
            <router-link :to="{ name: 'Login', query: { widget_id: widgetId } }" class="alert-link">login</router-link>.
        </div>
    </div>
</template>

<script>
    export default {
        name: 'LoginMessage',
        props: {
            userId: {
                type: Number,
                required: true
            },
            widgetId: {
                type: Number,
                required: true
            }
        },
        computed: {
            shouldShowMessage() {
                return this.userId === 12;
            }
        }
    }
</script>

<style scoped>
.custom-z-index {
    z-index: 1000;
}
</style>

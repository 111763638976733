<template>
    <div class="col-sm-9">
        <h4>
            Widget Categories
            <span class="badge rounded-pill bg-secondary">{{
                data.total
            }}</span>
        </h4>

        <button
            data-bs-toggle="modal"
            @click="$parent.$refs.createComponent.showCreateModal()"
            type="button"
            class="btn btn-primary me-2 float-end"
        >
            Create category
        </button>

        <form @submit.prevent="search(event)">
            <div class="input-group mb-3 float-end me-2" style="width: 300px">
                <input
                    v-model="search_keyword"
                    type="text"
                    class="form-control"
                    placeholder="Type any word..."
                    aria-label="Search"
                    aria-describedby="button-addon2"
                    v-on:keyup="detectEmptySearchBox(event)"
                />
                <button
                    class="btn btn-secondary"
                    type="submit"
                    id="button-addon2"
                >
                    Search
                </button>
            </div>
        </form>

        <div class="loader" v-if="loading"></div>
        <table class="table table-hovered" v-else>
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Description</th>
                    <th>Order</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="item in data.data" :key="item.id">
                    <td>{{ item.name }}</td>
                    <td>{{ item.description }}</td>
                    <td>{{ item.order }}</td>
                    <td>
                        <button
                            data-bs-toggle="modal"
                            @click="
                                $parent.$refs.updateComponent.showEditModal(
                                    $event,
                                    item.id
                                )
                            "
                            type="button"
                            class="btn btn-primary me-2"
                        >
                            Edit
                        </button>

                        <button
                            @click="
                                $parent.$refs.deleteComponent.showDeleteModal(
                                    item.id
                                )
                            "
                            type="button"
                            class="btn btn-danger me-2"
                        >
                            Delete
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
        <nav aria-label="Page navigation example">
            <ul class="pagination">
                <span v-for="item in data.links" :key="item.url" class="me-1">
                    <li :class="{ active: item.active }" class="page-item">
                        <a
                            v-if="item.url"
                            @click="read(event, item.url)"
                            class="page-link"
                            href="#"
                            ><span v-html="item.label"></span
                        ></a>
                    </li>
                </span>
            </ul>
        </nav>
    </div>
</template>

<script>
import store from "../../../store";

export default {
    data() {
        return {
            loading: true,
            data: "",

            id: "",
            name: "",
            desciption: "",

            edit_modal: null,
            delete_modal: null,

            search_keyword: "",
        };
    },
    methods: {
        async search(event) {
            const search_url =
                process.env.VUE_APP_API_URL +
                "widget-categories/search/" +
                this.search_keyword;

            this.read(event, search_url);
        },

        async detectEmptySearchBox() {
            console.log("detectEmptySearchBox " + this.search_keyword);
            if (!this.search_keyword) {
                this.read();
            }
        },

        async read(event, url) {
            if (event) {
                event.target.disabled = true;
            }

            // api request
            if (!url) {
                url = process.env.VUE_APP_API_URL + "widget-categories/read";
            }

            const res = await fetch(url, {
                headers: new Headers({
                    Authorization:
                        "Bearer " + store.getters.logged_in_user.data.token,
                }),
            });

            if (this.data && this.data.links) {
                this.data.links = "";
            }

            this.data = await res.json();
            this.loading = false;

            if (event) {
                event.target.disabled = false;
            }
        },
    },
};
</script>

<template>
<div id="widget-customizer" class="col-md-4 widget-customizer">
    <div class="d-flex align-items-start">
        <div class="nav flex-column nav-pills me-3" id="v-pills-tab" role="tablist" aria-orientation="vertical">
            <li class="nav-link active" id="v-pills-basic-tab" data-bs-toggle="pill" data-bs-target="#v-pills-basic" type="button" role="tab" aria-controls="v-pills-basic" aria-selected="false">Basic</li>
            <li class="nav-link" id="v-pills-updates-tab" data-bs-toggle="pill" data-bs-target="#v-pills-updates" type="button" role="tab" aria-controls="v-pills-updates" aria-selected="false">Updates</li>
            <li class="nav-link" id="v-pills-colors-tab" data-bs-toggle="pill" data-bs-target="#v-pills-colors" type="button" role="tab" aria-controls="v-pills-colors" aria-selected="false">Colors</li>
            <li class="nav-link" id="v-pills-font-tab" data-bs-toggle="pill" data-bs-target="#v-pills-font" type="button" role="tab" aria-controls="v-pills-font" aria-selected="false">Fonts</li>
            <li class="nav-link" id="v-pills-spacing-tab" data-bs-toggle="pill" data-bs-target="#v-pills-spacing" type="button" role="tab" aria-controls="v-pills-spacing" aria-selected="false">Spacing</li>
        </div>
        
        <form class="tab-content flex-fill" id="v-pills-tabContent" @input="handleInput">
            <div class="tab-pane fade show active" id="v-pills-basic" role="tabpanel" aria-labelledby="v-pills-basic-tab">
                <div class="container p-0 mb-4">
                    <div class="form-grid">
                        <div class="form-group">
                            <label>Name</label>
                            <input type="text" class="form-control" v-model="widget_name" id="widget_name">
                        </div>
                        <div class="form-group">
                            <label>Selector</label>
                            <input type="text" class="form-control" v-model="widget_settings.selector" id="selector">
                        </div>
                        <div class="form-group">
                            <label>Popup Title</label>
                            <input type="text" class="form-control" v-model="widget_settings.popup_title" id="popup_title">
                        </div>
                    </div>
                </div>
            </div>

            <div class="tab-pane fade" id="v-pills-updates" role="tabpanel" aria-labelledby="v-pills-updates-tab">
                <div class="form-grid">
                    <button
                        @click="parentRefs.createComponent.showCreateModal()"
                        type="button"
                        class="btn btn-primary"
                    >
                        Add Update
                    </button>
                    
                    <form @submit.prevent="parentRef.search(event, search_keyword)">
                        <div class="input-group">
                            <input
                                v-model="search_keyword"
                                type="text"
                                class="form-control"
                                placeholder="Type any word..."
                                aria-label="Search"
                                aria-describedby="button-addon2"
                                @input="parentRef.detectEmptySearchBox(search_keyword)"
                            />
                            <button
                                class="btn btn-secondary"
                                type="submit"
                                id="button-addon2"
                            >
                                Search
                            </button>
                        </div>
                    </form>
                </div>

                <div class="loader" v-if="!parentRef || parentRef.loading"></div>
                <div class="d-grid" v-else>
                    <div v-if="!parentRef.data.data || parentRef.data.data.length < 1" class="text-center">
                        No data found.
                    </div>

                    <div class="table-responsive">
                        <table class="table table-dark table-hover table-striped align-middle rounded-1 overflow-hidden">
                            <thead>
                                <tr>
                                    <th class="py-2 px-3">Title</th>
                                    <th class="py-2 px-3">Publish Date</th>
                                    <th class="py-2 px-3 text-center">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in parentRef.data.data" :key="item.id">
                                    <td class="py-2 px-3">{{ item.title }}</td>
                                    <td class="py-2 px-3">{{ publish_date_formatted(item.publish_date) }}</td>
                                    <td class="py-2 px-3 text-center">
                                        <button
                                            @click="parentRefs.updateComponent.showEditModal($event, item)"
                                            type="button"
                                            class="btn btn-sm btn-primary px-3 me-2"
                                        >
                                            Edit
                                        </button>
                                        <button
                                            @click="parentRefs.deleteComponent.showDeleteModal(item.id, $route.params.widget_id)"
                                            type="button"
                                            class="btn btn-sm btn-danger px-3 me-2"
                                        >
                                            Delete
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <nav v-if="parentRef && parentRef.data.data && parentRef.data.data.length > 0" aria-label="Page navigation example">
                    <ul class="pagination">
                        <span v-for="item in parentRef.data.links" :key="item.url" class="me-1">
                            <li :class="{ active: item.active }" class="page-item">
                                <a v-if="item.url" @click="read(event, item.url)" class="page-link" href="#">
                                    <span v-html="item.label"></span>
                                </a>
                            </li>
                        </span>
                    </ul>
                </nav>
            </div>
            
            <div class="tab-pane fade" id="v-pills-colors" role="tabpanel" aria-labelledby="v-pills-colors-tab">
                <div class="container p-0 mb-4">
                    <div class="form-grid">
                        <div class="form-group">
                            <label for="">Popup title text color</label>
                            <div class="form-group">
                                <input v-model="widget_settings.popup_title_color" type="text" id="qf-tb-text-color" class="form-control setting setting-color">
                                <PickrColorPicker v-model="widget_settings.popup_title_color" @input="handleInput" />
                            </div>
                        </div>

                        <div class="form-group">
                            <label for="">Title text color</label>
                            <div class="form-group">
                                <input v-model="widget_settings.title_color" type="text" id="qf-tb-text-color" class="form-control setting setting-color">
                                <PickrColorPicker v-model="widget_settings.title_color" @input="handleInput" />
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="">Product update text color</label>
                            <div class="form-group">
                                <input v-model="widget_settings.product_update_color" type="text" id="qf-tb-text-color" class="form-control setting setting-color">
                                <PickrColorPicker v-model="widget_settings.product_update_color" @input="handleInput" />
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="">Product update background color</label>
                            <div class="form-group">
                                <input v-model="widget_settings.product_update_background_color" type="text" id="qf-tb-text-color" class="form-control setting setting-color">
                                <PickrColorPicker v-model="widget_settings.product_update_background_color" @input="handleInput" />
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="">Link color</label>
                            <div class="form-group">
                                <input v-model="widget_settings.link_color" type="text" id="qf-tb-text-color" class="form-control setting setting-color">
                                <PickrColorPicker v-model="widget_settings.link_color" @input="handleInput" />
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="">Background color</label>
                            <div class="form-group">
                                <input v-model="widget_settings.background_color" type="text" id="qf-tb-text-color" class="form-control setting setting-color">
                                <PickrColorPicker v-model="widget_settings.background_color" @input="handleInput" />
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="">Badge text color</label>
                            <div class="form-group">
                                <input v-model="widget_settings.badge_text_color" type="text" id="qf-tb-text-color" class="form-control setting setting-color">
                                <PickrColorPicker v-model="widget_settings.badge_text_color" @input="handleInput" />
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="">Badge background color</label>
                            <div class="form-group">
                                <input v-model="widget_settings.badge_background_color" type="text" id="qf-tb-text-color" class="form-control setting setting-color">
                                <PickrColorPicker v-model="widget_settings.badge_background_color" @input="handleInput" />
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="">Close button color</label>
                            <div class="form-group">
                                <input v-model="widget_settings.close_button_color" type="text" id="qf-tb-text-color" class="form-control setting setting-color">
                                <PickrColorPicker v-model="widget_settings.close_button_color" @input="handleInput" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="tab-pane fade" id="v-pills-font" role="tabpanel" aria-labelledby="v-pills-font-tab">
                <div class="container p-0 mb-4">
                    <div class="form-grid">
                        <div class="form-group">
                            <label for="">Popup title font size</label>
                            <input v-model="widget_settings.popup_title_font_size" type="number" id="qf-tb-text" class="form-control setting">
                        </div>
                        <div class="form-group">
                            <label for="">Title font size</label>
                            <input v-model="widget_settings.title_font_size" type="number" id="qf-tb-text" class="form-control setting">
                        </div>
                        <div class="form-group">
                            <label for="">Product update font size</label>
                            <input v-model="widget_settings.product_update_font_size" type="number" id="qf-tb-text" class="form-control setting">
                        </div>
                    </div>
                </div>
            </div>

            <div class="tab-pane fade" id="v-pills-spacing" role="tabpanel" aria-labelledby="v-pills-spacing-tab">
                <div class="container p-0 mb-4">
                    <div class="form-grid">
                        <div class="form-group">
                            <label for="">Padding</label>
                            <input v-model="widget_settings.padding" type="number" id="qf-tb-padding" class="form-control setting">
                        </div>
                        <div class="form-group">
                            <label for="">Border radius</label>
                            <input v-model="widget_settings.border_radius" type="number" id="qf-tb-padding" class="form-control setting">
                        </div>
                        <div class="form-group">
                            <label for="">Product update padding</label>
                            <input v-model="widget_settings.product_update_padding" type="number" id="qf-tb-padding" class="form-control setting">
                        </div>
                        <div class="form-group">
                            <label for="">Product update border radius</label>
                            <input v-model="widget_settings.product_update_border_radius" type="number" id="qf-tb-padding" class="form-control setting">
                        </div>
                    </div>
                </div>
            </div>

            <div class="container p-0 mb-4">
                <div class="form-grid">
                    <button type="button" class="btn btn-primary" @click="save($event)">
                        <span v-if="isSaving" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        <span v-if="!isSaving">Save changes</span>
                    </button>
                </div>
            </div>
        </form>
   </div>
</div>
</template>
<style scoped>
    .widget-customizer{
        border: 1px solid #c6c6c6;
        border-radius: 8px;
        background: #000000;
        color:white;
        padding:20px 10px;
    }
    .widget-customizer .form-group{
        display:inline-flex;
    }

    .widget-customizer .nav-link{
        color: white;
        background: black;
        border-right: 1px solid #555 !important;
        border-radius:0px;
    }
    .widget-customizer .nav-link.active{
        color: white;
        background: black;
        border: 1px solid #555 !important;
        border-right: none !important;
        border-radius: 4px 0 0 4px;
    }
    .widget-customizer .container{
        display:grid;
    }
</style>
<script>

import axios from "axios";
import PickrColorPicker from "../../../../components/PickrColorPicker.vue";

export default {
    props: {
        parentRef: {
            type: Object,
            default: null,
        },
        parentRefs: {
            type: Object,
            default: null,
        },
    },
    components: {
        PickrColorPicker
    },
    data() {
        return {
            token: this.$store?.getters?.logged_in_user?.data?.token ?? '',
            widget_id: 0,
            data: "",
            isSaving: false,
            widget_name: "",
            search_keyword: "",
            widget_settings: {
                popup_title: "Product Updates",
                popup_title_color: "#555555",
                popup_title_font_size : 21,
                title_color : "#555555",
                title_font_size : 18,
                product_update_color: "#555555",
                product_update_font_size : 15,
                product_update_padding : 20,
                product_update_border_radius : 8,
                product_update_background_color : "#ffffff",
                link_color: "#3480dc",
                background_color: "#ffffff",
                close_button_color: "#555555",
                padding: 20,
                border_radius: 8,
                badge_text_color: "#555555",
                badge_background_color: "#ffffff",
                selector: ""
            }
        };
    },
    methods: {
        async read() {
            const widgetUrl = `${process.env.VUE_APP_API_URL}widgets/${this.$store.getters.logged_in_user.data.user.id}/${this.widget_id}`;
            const widgetSettingUrl = `${process.env.VUE_APP_API_URL}product-update/readByWidgetId/${this.widget_id}`;
            
            const headers = new Headers({
                Authorization: "Bearer " + this.token,
            });

            const [widgetResponse, widgetSettingResponse] = await Promise.all([
                fetch(widgetUrl, { headers }),
                fetch(widgetSettingUrl, { headers }),
            ]);


            const widgetData = await widgetResponse.json();
            const widgetSettingData = await widgetSettingResponse.json();

            if (widgetData) {
                this.widget_name = widgetData.data.name;
            }

            if (widgetSettingData) {
                this.widget_settings = widgetSettingData.settings;
            }
        },
        handleInput() {
            const event = new CustomEvent('previewDataUpdated', { 
                detail: { 
                    previewData: this.widget_settings 
                }
            });
            window.dispatchEvent(event);
        },
        async save(event){
            var __this = this;
            __this.isSaving = true;
            event.target.disabled = false;
            var data = __this.widget_settings;
            data.widget_id = __this.widget_id;

            try {
                const response = await axios.post(
                    process.env.VUE_APP_API_URL + `product-update/save`,
                    data,
                    {
                        headers: {
                            Authorization: "Bearer " + this.token,
                        },
                    }
                );

                if (response.data.success) {
                    await axios.put(
                        process.env.VUE_APP_API_URL + `widgets/${this.widget_id}`,
                        { name: __this.widget_name },
                        {
                            headers: {
                                Authorization: "Bearer " + this.token,
                            },
                        }
                    );
                }

                await this.read();
            } catch (error) {
                console.error('Error saving widget settings or updating name:', error);
            } finally {
                event.target.disabled = false;
                __this.isSaving = false;
                __this.preview();
            }
        },
        publish_date_formatted(date) {
            const dateObject = new Date(date);
            const options = {
                year: 'numeric',
                month: 'long',
                day: 'numeric'
            };
            return dateObject.toLocaleString('en-US', options);
        },
        preview (){
            let previewContainer = document.querySelector('[data-webynize-id="' + this.$route.params.widget_id + '"]');
            let widgetScript = document.querySelector('[src="' + process.env.VUE_APP_WIDGET_URL + 'product-updates.js' + '"]');

            if (previewContainer) {
                previewContainer.remove();
            }

            if (widgetScript) {
                widgetScript.remove();
            }
            previewContainer = document.createElement("div");
            previewContainer.setAttribute("data-webynize-id", this.$route.params.widget_id);
            previewContainer.classList.add("wbnz_product_update");
            widgetScript = document.createElement("script");
            widgetScript.src = process.env.VUE_APP_WIDGET_URL + `product-updates.js`;
            widgetScript.defer = true;
            document.getElementById("preview").appendChild(previewContainer);
            document.getElementById("preview").appendChild(widgetScript);

            widgetScript.onload = function() {
                window.dispatchEvent(new Event('load'));
            };
        }
    },
    mounted() {
        let __this = this;
        __this.widget_id = __this.$route.params.widget_id;
        __this.read();
        __this.preview();
    },
};
</script>

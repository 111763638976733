<template>
    <div class="row">
        <div class="col-sm-4"></div>
        <div class="col-sm-4">
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title mb-4">Signup to Webynize</h5>
                    <div>
                        <div class="d-grid mx-auto mb-2">
                            <a href="#" id="btn-google" class="btn btn-dark btn-block">
                                <img src="https://img.icons8.com/color/48/000000/google-logo.png" width="25" alt=""> 
                                Signup with Google
                            </a>
                        </div>
                        <div class="divider">or</div>
                        <label class="form-label">Email</label>
                        <input
                            type="email"
                            class="form-control mb-3"
                            required
                            v-model="email"
                        />

                        <button @click="signUp($event)" type="submit" class="btn btn-primary mb-3">
                            Signup
                        </button>

                        <div class="">
                            Already have an account?
                            <router-link to="/login/" class="card-link"
                                >Login</router-link
                            >
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-4"></div>
    </div>
</template>


<script>
import { mapActions } from "vuex";

export default {
    data() {
        return {
            token: "",
            email: "",
            password: "",
            sign_up_failed: false,
            widget_id: this.$route.query.widget_id
        };
    },
    methods: {
        ...mapActions(["signUpAuth"]),
        async signUp(event) {
            event.target.disabled = true;
            event.target.classList.add("loading-btn");
            var user = {
                token: this.token,
                email: this.email,
                ...(this.widget_id ? { widget_id: this.widget_id } : {})
            };
            try {
                await this.signUpAuth(user);
                event.target.disabled = false;
                event.target.classList.remove("loading-btn");
            } catch {
                this.login_failed = true;
                event.target.disabled = false;
                event.target.classList.remove("loading-btn");
            }
        },
    },
    mounted() {
        let __this = this;
        const recaptchaSiteKey = process.env.VUE_APP_RECAPTCHA_SITE_KEY;
        let url = process.env.VUE_APP_API_URL + 'google/loginOrSignup/';

        if (this.widget_id) {
            url += `?widget_id=${this.widget_id}`;
        }

        document.getElementById("btn-google").setAttribute("href", url);
        const script = document.createElement('script');
        script.src = 'https://www.google.com/recaptcha/api.js?render=' + recaptchaSiteKey;
        script.onload = () => {
            window.grecaptcha.ready(() => {
                window.grecaptcha.execute(recaptchaSiteKey, {
                    action: 'submit'
                }).then((token) => {
                    const badgeElement = document.querySelector('.grecaptcha-badge');
                    if (badgeElement) {
                        badgeElement.style.display = 'none';
                    }
                    script.remove();
                    __this.token = token;
                });
            });
        };
        document.body.appendChild(script);
    }
};
</script>

<style scoped>
</style>
<template>
<div id="widget-customizer" class="col-md-4 widget-customizer">
    <div class="d-flex align-items-start">
        <div class="nav flex-column nav-pills me-3" id="v-pills-tab" role="tablist" aria-orientation="vertical">
            <li class="nav-link active" id="v-pills-basic-tab" data-bs-toggle="pill" data-bs-target="#v-pills-basic" type="button" role="tab" aria-controls="v-pills-basic" aria-selected="false">Basic</li>
            <li class="nav-link" id="v-pills-colors-tab" data-bs-toggle="pill" data-bs-target="#v-pills-colors" type="button" role="tab" aria-controls="v-pills-colors" aria-selected="false">Colors</li>
            <li class="nav-link" id="v-pills-font-tab" data-bs-toggle="pill" data-bs-target="#v-pills-font" type="button" role="tab" aria-controls="v-pills-font" aria-selected="false">Fonts</li>
            <li class="nav-link" id="v-pills-alignment-positions-tab" data-bs-toggle="pill" data-bs-target="#v-pills-alignment-positions" type="button" role="tab" aria-controls="v-pills-alignment-positions" aria-selected="false">Alignments and Positions</li>
            <li class="nav-link" id="v-pills-spacing-tab" data-bs-toggle="pill" data-bs-target="#v-pills-spacing" type="button" role="tab" aria-controls="v-pills-spacing" aria-selected="false">Spacing</li>
        </div>

        <form class="tab-content flex-fill" id="v-pills-tabContent" @input="handleInput">
            <div class="tab-pane fade show active" id="v-pills-basic" role="tabpanel" aria-labelledby="v-pills-basic-tab">
                <div class="container p-0 mb-4">
                    <div class="form-grid">
                        <div class="form-group">
                            <label>Name</label>
                            <input type="text" class="form-control" v-model="widget_name" id="widget_name">
                        </div>
                        <div class="form-group">
                            <label>JSON data URL</label>
                            <input type="text" class="form-control" v-model="widget_settings.data_url" id="data_url">
                        </div>
                        <div class="form-group">
                            <label>Width</label>
                            <input type="text" class="form-control" v-model="widget_settings.width" id="width">
                        </div>
                        <div class="form-group">
                            <label>Hide after</label>
                            <input type="number" class="form-control" v-model="widget_settings.hide_after" id="hide_after">
                        </div>
                        <div class="form-group">
                            <div class="form-check">
                                <input @change="allPages" value="1" class="form-check-input" type="checkbox" id="all-pages" :checked="widget_settings.pages && widget_settings.pages.length < 1">
                                <label class="form-check-label" for="allow_close">
                                    All pages
                                </label>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="form-check">
                                <input value="1" v-model="widget_settings.allow_close" class="form-check-input" type="checkbox" id="allow_close" :checked="widget_settings.allow_close == 1">
                                <label class="form-check-label" for="allow_close">
                                    Allow close
                                </label>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="form-check">
                                <input value="1" v-model="widget_settings.loader" class="form-check-input" type="checkbox" id="loader" :checked="widget_settings.loader == 1">
                                <label class="form-check-label" for="loader">
                                    Show loader
                                </label>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Pages <br>(Separate pages URL by comma)</label>
                            <textarea v-model="widget_settings.pages" class="form-control" name="pages" id="pages" cols="30" rows="10"></textarea>
                        </div>
                    </div>
                </div>
            </div>

            <div class="tab-pane fade" id="v-pills-colors" role="tabpanel" aria-labelledby="v-pills-colors-tab">
                <div class="container p-0 mb-4">
                    <div class="form-grid">
                        <div class="form-group">
                            <label for="">Background color</label>
                            <div class="form-group">
                                <input v-model="widget_settings.background_color" type="text" id="qf-tb-text-color" class="form-control setting setting-color">
                                <PickrColorPicker v-model="widget_settings.background_color" @input="handleInput" />
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="">Content font color</label>
                            <div class="form-group">
                                <input v-model="widget_settings.content_font_color" type="text" id="qf-tb-bg-color" class="form-control setting setting-color">
                                <PickrColorPicker v-model="widget_settings.content_font_color" @input="handleInput" />
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="">Star color</label>
                            <div class="form-group">
                                <input v-model="widget_settings.star_color" type="text" id="qf-tb-icon-color" class="form-control setting setting-color">
                                <PickrColorPicker v-model="widget_settings.star_color" @input="handleInput" />
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="">Button background color</label>
                            <div class="form-group">
                                <input v-model="widget_settings.button_background_color" type="text" id="qf-tb-icon-color" class="form-control setting setting-color">
                                <PickrColorPicker v-model="widget_settings.button_background_color" @input="handleInput" />
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="">Button text color</label>
                            <div class="form-group">
                                <input v-model="widget_settings.button_text_color" type="text" id="qf-tb-icon-color" class="form-control setting setting-color">
                                <PickrColorPicker v-model="widget_settings.button_text_color" @input="handleInput" />
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="">Loader color</label>
                            <div class="form-group">
                                <input v-model="widget_settings.loader_color" type="text" id="qf-tb-text-color" class="form-control setting setting-color">
                                <PickrColorPicker v-model="widget_settings.loader_color" @input="handleInput" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="tab-pane fade" id="v-pills-font" role="tabpanel" aria-labelledby="v-pills-font-tab">
                <div class="container p-0 mb-4">
                    <div class="form-grid">
                        <div class="form-group">
                            <label for="">Content font size</label>
                            <input v-model="widget_settings.content_font_size" type="number" id="qf-tb-text" class="form-control setting">
                        </div>
                        <div class="form-group">
                            <label for="">Star size</label>
                            <input v-model="widget_settings.star_size" type="number" id="qf-tb-text" class="form-control setting">
                        </div>
                        <div class="form-group">
                            <label for="">Button font size</label>
                            <input v-model="widget_settings.button_font_size" type="number" id="qf-tb-text" class="form-control setting">
                        </div>
                    </div>
                </div>
            </div>

            <div class="tab-pane fade" id="v-pills-alignment-positions" role="tabpanel" aria-labelledby="v-pills-alignment-positions-tab">
                <div class="container p-0 mb-4">
                    <div class="form-grid">
                        <div class="form-group">
                            <label for="" >Content text align</label>
                            <select v-model="widget_settings.content_alignment" class="form-control setting" name="content_alignment" id="content_alignment">
                                <option value="0">Left</option>
                                <option value="1">Center</option>
                                <option value="2">Right</option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label for="" >Position</label>
                            <select v-model="widget_settings.position" class="form-control setting" name="position" id="position">
                                <option value="0">Lower Left Corner</option>
                                <option value="1">Lower Center</option>
                                <option value="2">Lower Right Corner</option>
                                <option value="3">Upper Left Corner</option>
                                <option value="4">Upper Center</option>
                                <option value="5">Upper Right Corner</option>
                                <option value="6">Left Center</option>
                                <option value="7">Right Center</option>
                                <option value="8">Center</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>

            <div class="tab-pane fade" id="v-pills-spacing" role="tabpanel" aria-labelledby="v-pills-spacing-tab">
                <div class="container p-0 mb-4">
                    <div class="form-grid">
                        <div class="form-group">
                            <label for="">Padding</label>
                            <input v-model="widget_settings.padding" type="number" id="qf-tb-padding" class="form-control setting">
                        </div>
                    </div>
                </div>
            </div>

            <div class="container p-0 mb-4">
                <div class="form-grid">
                    <button type="button" class="btn btn-primary" @click="save($event)">
                        <span v-if="isSaving" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        <span v-if="!isSaving">Save changes</span>
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>
</template>

<style scoped>
    .widget-customizer{
        border: 1px solid #c6c6c6;
        border-radius: 8px;
        background: #000000;
        color:white;
        padding:20px 10px;
    }
    .widget-customizer .form-group{
        display:inline-flex;
    }

    .widget-customizer .nav-link{
        color: white;
        background: black;
        border-right: 1px solid #555 !important;
        border-radius:0px;
    }
    .widget-customizer .nav-link.active{
        color: white;
        background: black;
        border: 1px solid #555 !important;
        border-right: none !important;
        border-radius: 4px 0 0 4px;
    }
    .widget-customizer .container{
        display:grid;
    }
</style>

<script>
import axios from "axios";
import PickrColorPicker from "../../../../components/PickrColorPicker.vue";

export default {
    components: {
        PickrColorPicker,
    },
    data() {
        return {
            token: this.$store?.getters?.logged_in_user?.data?.token ?? '',
            widget_url: process.env.VUE_APP_WIDGET_URL,
            widget_id: "",
            data: "",
            id: "",
            isSaving: false,
            notify_on: "",
            widget_name: "",
            widget_settings: {
                widget_id: 0,
                background_color : "#ffffff",
                content_font_color: "#555555",
                content_font_size: 12,
                content_alignment: 0,
                star_color: "#1e65b5",
                star_size: 12,
                button_background_color: "#1972f5",
                button_text_color: "#ffffff",
                button_font_size: 0,
                padding: 8,
                position: 0,
                hide_after: 6000,
                show_transition: 0,
                allow_close: 0,
                loader: 0,
                loader_color: "#025DFB",
                width: 350,
                data_url: "",
                pages: ""
            }
        };
    },
    methods: {
        async read() {
            const widgetUrl = `${process.env.VUE_APP_API_URL}widgets/${this.$store.getters.logged_in_user.data.user.id}/${this.widget_id}`;
            const widgetSettingUrl = `${process.env.VUE_APP_API_URL}social-proofs/readByWidgetId/${this.widget_id}`;
            
            const headers = new Headers({
                Authorization: "Bearer " + this.token,
            });

            const [widgetResponse, widgetSettingResponse] = await Promise.all([
                fetch(widgetUrl, { headers }),
                fetch(widgetSettingUrl, { headers }),
            ]);

            const widgetData = await widgetResponse.json();
            const widgetSettingData = await widgetSettingResponse.json();

            if (widgetData) {
                this.widget_name = widgetData.data.name;
            }

            if (widgetSettingData) {
                this.widget_settings = widgetSettingData;
            }
        },
        handleInput() {
            const event = new CustomEvent('previewDataUpdated', { 
                detail: { 
                    previewData: this.widget_settings 
                }
            });
            window.dispatchEvent(event);
        },
        async save(event){
            var __this = this;
            __this.isSaving = true;
            event.target.disabled = false;
            var data = __this.widget_settings;
            data.widget_id = __this.widget_id;

            try {
                const response = await axios.post(
                    process.env.VUE_APP_API_URL + `social-proofs/save/${__this.widget_id}`,
                    data,
                    {
                        headers: {
                            Authorization: "Bearer " + this.token,
                        },
                    }
                );

                if (response.data.id) {
                    await axios.put(
                        process.env.VUE_APP_API_URL + `widgets/${this.widget_id}`,
                        { name: __this.widget_name },
                        {
                            headers: {
                                Authorization: "Bearer " + this.token,
                            },
                        }
                    );
                }

                await this.read();
            } catch (error) {
                console.error('Error saving widget settings or updating name:', error);
            } finally {
                event.target.disabled = false;
                __this.isSaving = false;
                __this.preview();
            }
        },
        allPages() {

            if (event.target.checked) {
                this.widget_settings.pages = "";
            }
        },
        preview (){
            let previewContainer = document.querySelector('[data-webynize-id="' + this.$route.params.widget_id + '"]');
            let socialProofScript = document.querySelector('[src="' + process.env.VUE_APP_WIDGET_URL + 'social-proof.js' + '"]');

            if (previewContainer) {
                previewContainer.remove();
            }

            if (socialProofScript) {
                socialProofScript.remove();
            }
            previewContainer = document.createElement("div");
            previewContainer.setAttribute("data-webynize-id", this.$route.params.widget_id);
            previewContainer.classList.add("wbnz_social_proof");
            socialProofScript = document.createElement("script");
            socialProofScript.src = process.env.VUE_APP_WIDGET_URL + `social-proof.js`;
            socialProofScript.defer = true;
            document.getElementById("preview").appendChild(previewContainer);
            document.getElementById("preview").appendChild(socialProofScript);

            socialProofScript.onload = function() {
                window.dispatchEvent(new Event('load'));
            };
        }
    },
    mounted(){
        var __this = this;
        __this.widget_id = this.$route.params.widget_id;
        __this.read();
        __this.preview();
    }
};
</script>

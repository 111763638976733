<template>
    <div class="row">
        <div class="col-sm-4"></div>
        <div class="col-sm-4">
            <div v-if="login_failed" class="alert alert-danger">
                Login failed. Please try again or contact support.
            </div>
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title mb-4">Login to Webynize</h5>
                    <div class="d-grid mx-auto mb-2">
                        <a href="#" id="btn-google" class="btn btn-dark btn-block">
                            <img src="https://img.icons8.com/color/48/000000/google-logo.png" width="25" alt=""> 
                            Login with Google
                        </a>
                    </div>
                    <div class="divider">or</div>
                    <label class="form-label">Email</label>
                    <input
                        v-model="email"
                        type="email"
                        class="form-control mb-3"
                        required
                    />

                    <label class="form-label">Password</label>
                    <input
                        v-model="password"
                        type="password"
                        class="form-control mb-3"
                        required
                    />

                    <button @click="login($event)" class="btn btn-primary mb-3">
                        Login
                    </button>

                    <div class="">
                        <router-link to="/signup/" class="card-link"
                            >Sign up</router-link
                        >
                        <router-link to="/forgot/" class="card-link"
                            >Forgot password?</router-link
                        >
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-4"></div>
    </div>
</template>

<script>

import { mapActions } from "vuex";

export default {
    data() {
        return {
            token: "",
            email: "",
            password: "",
            login_failed: false,
            widget_id: this.$route.query.widget_id
        };
    },
    methods: {
        ...mapActions(["loginAuth"]),
        async login(event) {
            console.log("clicked", event);

            event.target.disabled = true;
            event.target.classList.add("loading-btn");
            try {
                var user = {
                    token: this.token,
                    email: this.email,
                    password: this.password,
                    ...(this.widget_id ? { widget_id: this.widget_id } : {})
                };
                await this.loginAuth(user);
                event.target.disabled = false;
                event.target.classList.remove("loading-btn");
            } catch {
                this.login_failed = true;
                event.target.disabled = false;
                event.target.classList.remove("loading-btn");
            }
        },
    },
    mounted(){
        let __this = this;
        const recaptchaSiteKey = process.env.VUE_APP_RECAPTCHA_SITE_KEY;
        let url = process.env.VUE_APP_API_URL + 'google/loginOrSignup/';

        if (this.widget_id) {
            url += `?widget_id=${this.widget_id}`;
        }
        
        document.getElementById("btn-google").setAttribute("href", url);
        const script = document.createElement('script');
        script.src = 'https://www.google.com/recaptcha/api.js?render='+recaptchaSiteKey;
        script.onload = () => {
            window.grecaptcha.ready(() => {
                window.grecaptcha.execute(recaptchaSiteKey, {
                    action: 'submit'
                }).then((token) => {
                    const badgeElement = document.querySelector('.grecaptcha-badge');
                    if (badgeElement) {
                        badgeElement.style.display = 'none';
                    }
                    __this.token = token;
                    script.remove();
                });
            });
        };
        document.body.appendChild(script);
    }
};
</script>

<style scoped>
</style>
<template>
    <div id="widget-customizer" class="col-md-4 widget-customizer">
        <div class="d-flex align-items-start">
            <div
                class="nav flex-column nav-pills me-3"
                id="v-pills-tab"
                role="tablist"
                aria-orientation="vertical"
            >
                <li
                    class="nav-link active"
                    id="v-pills-basic-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#v-pills-basic"
                    type="button"
                    role="tab"
                    aria-controls="v-pills-basic"
                    aria-selected="false"
                >
                    Basic
                </li>
                <li
                    class="nav-link"
                    id="v-pills-colors-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#v-pills-colors"
                    type="button"
                    role="tab"
                    aria-controls="v-pills-colors"
                    aria-selected="false"
                >
                    Colors
                </li>
                <li
                    class="nav-link"
                    id="v-pills-font-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#v-pills-font"
                    type="button"
                    role="tab"
                    aria-controls="v-pills-font"
                    aria-selected="false"
                >
                    Fonts
                </li>
                <li
                    class="nav-link"
                    id="v-pills-alignment-positions-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#v-pills-alignment-positions"
                    type="button"
                    role="tab"
                    aria-controls="v-pills-alignment-positions"
                    aria-selected="false"
                >
                    Position
                </li>
                <li
                    class="nav-link"
                    id="v-pills-spacing-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#v-pills-spacing"
                    type="button"
                    role="tab"
                    aria-controls="v-pills-spacing"
                    aria-selected="false"
                >
                    Spacings
                </li>
            </div>

            <form class="tab-content" id="v-pills-tabContent" @input="handleInput">
                <div
                    class="tab-pane fade show active"
                    id="v-pills-basic"
                    role="tabpanel"
                    aria-labelledby="v-pills-basic-tab"
                >
                <div class="container p-0 mb-4">
                    <div class="form-grid">
                        <div class="form-group form-grid-span-full">
                            <label>Name</label>
                            <input type="text" class="form-control" v-model="widget_name" id="widget_name">
                        </div>
                        <div class="form-group form-grid-span-full">
                            <label>Content Text</label>
                            <textarea
                                v-model="widget_settings.content_text"
                                class="form-control"
                                name="content_text"
                                id="content_text"
                                cols="30"
                                rows="10"
                            ></textarea>
                        </div>
                        <div class="form-group">
                            <label>Button Text</label>
                            <input
                                type="text"
                                class="form-control"
                                name="button_text"
                                v-model="widget_settings.button_text"
                                id="button_text"
                            />
                        </div>
                        <div class="form-group">
                            <label>URL</label>
                            <input
                                type="text"
                                class="form-control"
                                v-model="widget_settings.button_url"
                                id="button_url"
                                placeholder="Enter URL (e.g., https://example.com/)"
                            />
                        </div>
                        <div class="form-group">
                            <div class="form-check">
                                <input value="0" v-model="widget_settings.open_in_new_tab" class="form-check-input" type="checkbox" id="open_in_new_tab" :checked="widget_settings.open_in_new_tab == 1">
                                <label class="form-check-label" for="open_in_new_tab">
                                    Open Link in New Tab
                                </label>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="form-check">
                                <input value="0" v-model="widget_settings.allow_close" class="form-check-input" type="checkbox" id="allow_close" :checked="widget_settings.allow_close == 1">
                                <label class="form-check-label" for="allow_close">
                                    Allow Close
                                </label>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Show Again After (Number of Days)</label>
                            <input
                                type="number"
                                class="form-control"
                                v-model="widget_settings.days_until_next_display"
                                id="days_until_next_display"
                                placeholder="Enter days (e.g., 1)"
                            />
                        </div>
                    </div>
                </div>
                </div>

                <div
                    class="tab-pane fade"
                    id="v-pills-colors"
                    role="tabpanel"
                    aria-labelledby="v-pills-colors-tab"
                >
                <div class="container p-0 mb-4">
                    <div class="form-grid">
                        <div class="form-group">
                            <label for="">Content background color</label>
                            <div class="form-group">
                                <input
                                    v-model="
                                        widget_settings.content_background_color
                                    "
                                    type="text"
                                    id="content_background_color"
                                    class="form-control setting setting-color"
                                />
                                <PickrColorPicker v-model="widget_settings.content_background_color" @input="handleInput" />
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="">Content font color</label>
                            <div class="form-group">
                                <input
                                    v-model="widget_settings.content_font_color"
                                    type="text"
                                    id="qf-tb-bg-color"
                                    class="form-control setting setting-color"
                                />
                                <PickrColorPicker v-model="widget_settings.content_font_color" @input="handleInput" />
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="">Button background color</label>
                            <div class="form-group">
                                <input
                                    v-model="
                                        widget_settings.button_background_color
                                    "
                                    type="text"
                                    id="button_background_color"
                                    class="form-control setting setting-color"
                                />
                                <PickrColorPicker v-model="widget_settings.button_background_color" @input="handleInput" />
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="">Button text color</label>
                            <div class="form-group">
                                <input
                                    v-model="widget_settings.button_font_color"
                                    type="text"
                                    id="button_font_color"
                                    class="form-control setting setting-color"
                                />
                                <PickrColorPicker v-model="widget_settings.button_font_color" @input="handleInput" />
                            </div>
                        </div>
                    </div>
                </div>

                </div>

                <div
                    class="tab-pane fade"
                    id="v-pills-font"
                    role="tabpanel"
                    aria-labelledby="v-pills-font-tab"
                >
                    <div class="container p-0 mb-4">
                        <div class="form-grid">
                            <div class="form-group">
                                <label for="">Content font size</label>
                                <input
                                    v-model="widget_settings.content_font_size"
                                    type="number"
                                    id="content_font_size"
                                    class="form-control setting"
                                />
                            </div>
                            <div class="form-group">
                                <label for="">Button font size</label>
                                <input
                                    v-model="widget_settings.button_font_size"
                                    type="number"
                                    id="button_font_size"
                                    class="form-control setting"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    class="tab-pane fade"
                    id="v-pills-alignment-positions"
                    role="tabpanel"
                    aria-labelledby="v-pills-alignment-positions-tab"
                >
                    <div class="container p-0 mb-4">
                        <div class="form-grid">
                            <div class="form-group">
                                <label for="">Placement</label>
                                <select
                                    v-model="widget_settings.placement"
                                    class="form-control setting"
                                    name="content_text_align"
                                    id="content_text_align"
                                    @change="handleInput"
                                >
                                    <option value="top">Top</option>
                                    <option value="bottom">Bottom</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    class="tab-pane fade"
                    id="v-pills-spacing"
                    role="tabpanel"
                    aria-labelledby="v-pills-spacing-tab"
                >
                    <div class="container p-0 mb-4">
                        <div class="form-grid">
                            <div class="form-group">
                                <label for="">Padding (Top & Bottom)</label>
                                <input
                                    v-model="widget_settings.padding_top_bottom"
                                    type="number"
                                    id="padding_top_bottom"
                                    class="form-control setting me-2"
                                />
                            </div>
                            <div class="form-group">
                                <label for="">Padding (Left & Right)</label>
                                <input
                                    v-model="widget_settings.padding_left_right"
                                    type="number"
                                    id="padding_left_right"
                                    class="form-control setting me-2"
                                />
                            </div>
                        </div>
                    </div>

                </div>

                <div class="container p-0 mb-4">
                    <div class="form-grid">
                        <button
                            type="button"
                            class="btn btn-primary"
                            @click="save($event)"
                        >
                            <span v-if="isSaving" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            <span v-if="!isSaving">Save changes</span>
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<style scoped>
.widget-customizer {
    border: 1px solid #c6c6c6;
    border-radius: 8px;
    background: #000000;
    color: white;
    padding: 20px 10px;
}
.widget-customizer .form-group {
    display: inline-flex;
}

.widget-customizer .nav-link {
    color: white;
    background: black;
    border-right: 1px solid #555 !important;
    border-radius: 0px;
}
.widget-customizer .nav-link.active {
    color: white;
    background: black;
    border: 1px solid #555 !important;
    border-right: none !important;
    border-radius: 4px 0 0 4px;
}
.widget-customizer .container {
    display: grid;
}
</style>

<script>
import axios from "axios";
import PickrColorPicker from "../../../../components/PickrColorPicker.vue";

export default {
    components: {
        PickrColorPicker,
    },
    data() {
        return {
            token: this.$store?.getters?.logged_in_user?.data?.token ?? "",
            isSaving: false,
            widget_url: process.env.VUE_APP_WIDGET_URL,
            widget_id: "",
            data: "",
            id: "",
            widget_name: "",
            widget_settings: {
                widget_id: 0,
                content_text: "Welcome to our site! Don’t miss our latest updates and special offers.",
                button_text: "Get Started",
                button_url: "",
                open_in_new_tab: 0,
                content_background_color: "",
                content_font_color: "",
                button_background_color: "",
                button_text_color: "",
                content_font_size: "",
                button_font_size: "",
                placement: "top",
                allow_close: 0,
                days_until_next_display: 1,
                padding_top_bottom: 10,
                padding_left_right: 120,
            }
        };
    },
    methods: {
        async read() {
            const widgetUrl = `${process.env.VUE_APP_API_URL}widgets/${this.$store.getters.logged_in_user.data.user.id}/${this.widget_id}`;
            const widgetSettingUrl = `${process.env.VUE_APP_API_URL}announcements/readByWidgetId/${this.widget_id}`;
            
            const headers = new Headers({
                Authorization: "Bearer " + this.token,
            });

            const [widgetResponse, widgetSettingResponse] = await Promise.all([
                fetch(widgetUrl, { headers }),
                fetch(widgetSettingUrl, { headers }),
            ]);

            const widgetData = await widgetResponse.json();
            const widgetSettingData = await widgetSettingResponse.json();


            if (widgetData) {
                this.widget_name = widgetData.data.name;
            }

            if (widgetSettingData) {
                this.widget_settings = widgetSettingData;
            }
        },
        handleInput() {
            const event = new CustomEvent('previewDataUpdated', { 
                detail: { 
                    previewData: this.widget_settings 
                }
            });
            window.dispatchEvent(event);
        },
        async save(event) {
            var __this = this;
            __this.isSaving = true;
            event.target.disabled = false;
            var data = __this.widget_settings;
            data.widget_id = __this.widget_id;

            try {
                const response = await axios.post(
                    process.env.VUE_APP_API_URL + `announcements/save/${__this.widget_id}`,
                    data,
                    {
                        headers: {
                            Authorization: "Bearer " + this.token,
                        },
                    }
                );

                if (response.data.id) {
                    await axios.put(
                        process.env.VUE_APP_API_URL + `widgets/${this.widget_id}`,
                        { name: __this.widget_name },
                        {
                            headers: {
                                Authorization: "Bearer " + this.token,
                            },
                        }
                    );
                }

                __this.preview();
            } catch (error) {
                console.error('Error saving widget settings or updating name:', error);
            } finally {
                event.target.disabled = false;
                __this.isSaving = false;
            }
        },
        handleOpenInNewTabChange(event) {
            this.widget_settings.open_in_new_tab = event.target.checked;
        },
        handleAllowClose(event) {
            this.widget_settings.allow_close = event.target.checked;
        },
        preview (){
            let previewContainer = document.querySelector('[data-webynize-id="' + this.$route.params.widget_id + '"]');
            let widgetScript = document.querySelector('[src="' + process.env.VUE_APP_WIDGET_URL + 'announcements.js' + '"]');

            if (previewContainer) {
                previewContainer.remove();
            }

            if (widgetScript) {
                widgetScript.remove();
            }
            previewContainer = document.createElement("div");
            previewContainer.setAttribute("data-webynize-id", this.$route.params.widget_id);
            previewContainer.classList.add("wbnz-announcements");
            widgetScript = document.createElement("script");
            widgetScript.src = process.env.VUE_APP_WIDGET_URL + `announcements.js`;
            widgetScript.defer = true;
            document.getElementById("preview").appendChild(previewContainer);
            document.getElementById("preview").appendChild(widgetScript);

            widgetScript.onload = function() {
                window.dispatchEvent(new Event('load'));
            };
        },
    },
    watch: {
        'widget_settings.open_in_new_tab'(newValue) {
            this.widget_settings.open_in_new_tab = newValue ? 1 : 0;
        },
        'widget_settings.allow_close'(newValue) {
            this.widget_settings.allow_close = newValue ? 1 : 0;
        }
    },
    mounted() {
        var __this = this;
        __this.widget_id = this.$route.params.widget_id;
        __this.read();
        __this.preview();
    }
};
</script>

<template>
    <div class="coming-soon">
        <h1 class="title">{{ title }}</h1>
        <p class="paragraph">{{ message }}</p>
    </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: 'Coming Soon',
    },
    message: {
      type: String,
      default: 'Exciting things are on the way. Stay tuned!',
    },
  },
};
</script>

<style scoped>
.coming-soon {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 40px 20px;
    margin: 20px 0;
    text-transform: uppercase;
}

.title {
    display: inline-block;
    font-weight: 700;
    font-size: 56px;
    line-height: 117.857%;
    color: #000;
    letter-spacing: 0.084px;
    background: linear-gradient(92deg, #19FB9B 11.36%, #199890 54.3%, #005F59 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.paragraph {
    color: #6D756F;
    font-size: 18px;
}

@media (max-width: 768px) {
    .title {
        font-size: 36px;
    }

    .paragraph {
        font-size: 12px;
    }
}

@media (max-width: 480px) {
    .title {
        font-size: 33px;
    }

    .paragraph {
        font-size: 11px;
    }
}
</style>
<template>
    <div class="container p-5 pt-0">
        <h4>
            Customize Vote Button widget
        </h4>

        <EmbedCode className="wbnz-vote-button" widgetName="vote-button" />

        <button
            @click="$parent.$refs.voteViewerComponent.showVoteViewer($route.params.widget_id)"
            type="button"
            class="btn btn-primary me-2 fs-5 d-flex align-items-center float-end"
        >
            View Votes
        </button>

        <br>

        <div class="row w-100">
            <Sidebar/>
            <div class="col-md-8">
                <div id="preview">

                </div>
            </div>
        </div>
    </div>
</template>

<style>
</style>

<script>
import Sidebar from "./components/Sidebar";
import EmbedCode from "../../components/EmbedCode.vue";

export default {
    components: {
        Sidebar,
        EmbedCode
    },
    data() {
        return {
            widget_url: process.env.VUE_APP_WIDGET_URL,
            widget_id: ""
        };
    },
    mounted(){
        var __this = this;
        __this.widget_id = this.$route.params.widget_id;
    },
};
</script>
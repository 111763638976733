<template>
    <button class="btn btn-primary me-2 fs-5 d-flex align-items-center float-end" @click="showModal">View Emails</button>
    <div class="modal" tabindex="-1" id="exitIntentEmailsModal" ref="exitIntentEmailsModal">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Exit Intent Popup Emails</h5>
                    <button type="button" class="btn-close" @click="hideModal"></button>
                </div>
                <div class="loader" v-if="loading"></div>
                <div class="modal-body" v-else>
                    <ExitIntentPopupEmailTable
                        :emails="emails"
                        :pagination="pagination"
                        @page-changed="fetchEmails"
                        @delete-email="handleDeleteEmail"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ExitIntentPopupEmailTable from './components/ExitIntentPopupEmailTable.vue';
import axios from "axios";
import { Modal } from "bootstrap";

export default {
    components: {
        ExitIntentPopupEmailTable,
    },
    data() {
        return {
            token: this.$store?.getters?.logged_in_user?.data?.token ?? '',
            loading: true,
            emails: [],
            pagination: {},
            modalInstance: null,
            widget_id: this.$route.params.widget_id,
        };
    },
    methods: {
        async fetchEmails(page = 1) {
            this.loading = true;

            try {
                const response = await axios.get(`${process.env.VUE_APP_API_URL}exit-intent-popups/${this.widget_id}/emails?page=${page}&widget_id=${this.widget_id}`, {
                    headers: {
                        Authorization: `Bearer ${this.token}`
                    }
                });
                this.emails = response.data.data;
                this.pagination = response.data.meta;
            } catch (error) {
                console.error("Error fetching emails:", error);
            }  finally {
                this.loading = false;
            }
        },
        async handleDeleteEmail(emailId) {
            this.loading = true;

            try {
                await axios.delete(`${process.env.VUE_APP_API_URL}exit-intent-popups/emails/${emailId}?widget_id=${this.widget_id}`, {
                    headers: {
                        Authorization: `Bearer ${this.token}`
                    }
                });
                await this.fetchEmails(this.pagination.current_page);
            } catch (error) {
                console.error("Error deleting email:", error.response ? error.response.data : error.message);
            } finally {
                this.loading = false;
            }
        },
        showModal() {
            this.modalInstance = new Modal(this.$refs.exitIntentEmailsModal);
            this.modalInstance.show();
            this.fetchEmails();
        },
        hideModal() {
            if (this.modalInstance) {
                this.modalInstance.hide();
            }
        },
    },
    mounted() {
        this.fetchEmails();
    },
};
</script>

<style scoped>
</style>

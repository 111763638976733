<template>
    <div class="col-sm-12">
        <div class="text-center">
            <h2>Create widget</h2>
            <div style="margin: 0px auto !important;" class="col-md-3">
                <div class="text-start mb-2">Type</div>
                <select
                    id="widget_type_id"
                    name="widget_type_id"
                    type="text"
                    class="form-select mb-3"
                >
                    <option
                        v-for="widget_type in widget_types"
                        :key="widget_type.id"
                        :value="widget_type.id"
                        :selected="widget_type.id == selected_type"
                    >
                        {{ widget_type.name }}
                    </option>
                </select>
                <div class="text-start mb-2">Name</div>
                <input
                    v-model="name"
                    type="text"
                    class="form-control mb-3"
                    required
                />
                <button
                        type="button"
                        class="w-100 btn btn-primary"
                        @click="create($event)"
                >
                        Next
                </button>
            </div>
        </div>
        <div
            class="modal"
            id="signup_modal"
            tabindex="-1"
            aria-labelledby="SignUpModalLabel"
            aria-hidden="true"
        >
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="SignUpModalLabel">
                            You’re almost there!
                        </h5>
                        <button
                            type="button"
                            class="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></button>
                    </div>
                    <div class="modal-body">
                        <div class="bb-content">
                            <p>Please sign up so we can save your progress on creating widgets to your account.</p>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <router-link
                            to="/signup/"
                            class="btn btn-primary"
                        > Sign up now
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import { Modal } from "bootstrap";

export default {
    data() {
        return {
            selected_type: 0,
            token: "",
            signup_modal: null,
            widget_types: [],
        };
    },
    methods: {
        async readWidgetTypes(event, url) {
            if (event) {
                event.target.disabled = true;
            }

            if (!url) {
                url = process.env.VUE_APP_API_URL + "widget-types/status/1";
            }
            
            const res = await fetch(url);

            this.widget_types = await res.json();
            console.log(document.getElementById("widget_type_id"));

            if (event) {
                event.target.disabled = false;
            }
        },
        async create(event) {
            event.target.classList.add("loading-btn");
            var select = document.getElementById("widget_type_id");
            this.widget_type_id = select.value;
            var user_id = this.$store && this.$store.getters && 
                        this.$store.getters.logged_in_user &&
                            this.$store.getters.logged_in_user.data &&
                            this.$store.getters.logged_in_user.data.user.id ? this.$store.getters.logged_in_user.data.user.id : 0;
            axios
            .post(
                process.env.VUE_APP_API_URL + "widgets",
                {
                    name: this.name,
                    user_id: user_id,
                    widget_type_id: this.widget_type_id
                }
            )
            .then(
                (response) => {
                    if (response.data.id) {
                        if(this.widget_type_id == 1){
                            this.$router.push(`/update-widget/tooltips/${response.data.id}`);
                        }
                        else if(this.widget_type_id == 2){
                            this.$router.push(`/update-widget/onboarding-checklist-items/${response.data.id}`);
                        }
                        else if(this.widget_type_id == 3){
                            this.$router.push(`/update-widget/quick-feedback/${response.data.id}`);
                        }
                        else if(this.widget_type_id == 4){
                            this.$router.push(`/update-widget/product-updates/${response.data.id}`);
                        }
                        else if(this.widget_type_id == 5){
                            this.$router.push(`/update-widget/announcements/${response.data.id}`);
                        }
                        else if(this.widget_type_id == 6){
                            this.$router.push(`/update-widget/social-proofs/${response.data.id}`);
                        }
                        else if(this.widget_type_id == 7){
                            this.$router.push(`/update-widget/vote-button/${response.data.id}`);
                        }
                        else if(this.widget_type_id == 12){
                            this.$router.push(`/update-widget/exit-intent-popup/${response.data.id}`);
                        }
                        else if(this.widget_type_id == 13){
                            this.$router.push(`/update-widget/back-to-top-button/${response.data.id}`);
                        }
                        else if(this.widget_type_id == 17){
                            this.$router.push(`/update-widget/cookie-consent/${response.data.id}`);
                        }
                        else{
                            this.$router.push("/widgets/");
                        }
                    }
                    event.target.classList.remove("loading-btn");
                },
                (error) => {
                    event.target.classList.remove("loading-btn");
                    console.log(error);
                }
            );
        },
        showSignupModal() {
            this.signup_modal.show();
        },
    },
    mounted() {
        this.selected_type = this.$route.query.widget_type ? this.$route.query.widget_type : 0;
        this.readWidgetTypes();
        this.signup_modal = new Modal(
            document.getElementById("signup_modal")
        );
    },
    watch: {
        '$route.query.widget_type'(newVal) {
            this.selected_type = newVal ? newVal : 0;
        },
    },
};
</script>

<template>
    <div class="container p-5">
        <h4>
            Announcements

        <EmbedCode className="wbnz-announcements" widgetName="announcements" />

        </h4>

        <br>
        <div class="row">
            <Sidebar/>
            <div class="col-md-8">
                <div id="preview">

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import EmbedCode from "../../components/EmbedCode.vue";
import Sidebar from "./components/Sidebar";

export default {
    components: {
        Sidebar,
        EmbedCode
    },
    data() {
        return {
            widget_url: process.env.VUE_APP_WIDGET_URL,
            widget_id: ""
        };
    },
    mounted(){
        var __this = this;
        __this.widget_id = this.$route.params.widget_id;
    },
};
</script>
<template>
    <div class="col-sm-9">
        <h4>
            Cache
        </h4>

        <div>
            <button class="btn btn-primary" @click="deleteCache">
                <span v-if="isLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                <span v-if="!isLoading">Delete Integrations Cache</span>
                <span v-else>Deleting...</span>
            </button>
        </div>

        <div v-if="responseMessage" class="mt-3">
            <p>{{ responseMessage }}</p>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            responseMessage: '',
            isLoading: false,
        };
    },
    methods: {
        async deleteCache() {
            this.isLoading = true;
            try {
                const deletePath = 'integrations';
                const response = await fetch(`${process.env.VUE_APP_WEBSITE_URL}delete_specific_cache.php?path=${encodeURIComponent(deletePath)}`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                this.responseMessage = `Deleted Cache Files: ${data.deleted_cache_files}`;
            } catch (error) {
                this.responseMessage = 'Error deleting cache: ' + error.message;
            }
            this.isLoading = false;
        },
    },
};
</script>

<template>
    <div class="table-responsive">
        <table class="table table-striped table-bordered">
            <thead>
                <tr>
                    <th>ID</th>
                    <th>Email</th>
                    <th>Submitted On</th>
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="email in emails" :key="email.id">
                    <td>{{ email.id }}</td>
                    <td>{{ email.email }}</td>
                    <td>{{ formatDate(email.created_at) }}</td>
                    <td>
                        <button class="btn btn-danger btn-sm" @click="deleteEmail(email.id)">Delete</button>
                    </td>
                </tr>
            </tbody>
        </table>

        <nav aria-label="Page navigation">
            <ul class="pagination">
                <li class="page-item" :class="{ disabled: !pagination.prev_page_url }">
                    <button class="page-link" @click="changePage(pagination.current_page - 1)">Previous</button>
                </li>
                <li
                    class="page-item"
                    v-for="page in pagination.last_page"
                    :key="page"
                    :class="{ active: pagination.current_page === page }"
                >
                    <button class="page-link" @click="changePage(page)">{{ page }}</button>
                </li>
                <li class="page-item" :class="{ disabled: !pagination.next_page_url }">
                    <button class="page-link" @click="changePage(pagination.current_page + 1)">Next</button>
                </li>
            </ul>
        </nav>
    </div>
</template>

<script>
export default {
    props: {
        emails: {
            type: Array,
            required: true,
        },
        pagination: {
            type: Object,
            required: true,
        },
    },
    methods: {
        formatDate(dateString) {
            return new Date(dateString).toLocaleDateString();
        },
        deleteEmail(emailId) {
            this.$emit('delete-email', emailId);
        },
        changePage(page) {
            if (page > 0 && page <= this.pagination.last_page) {
                this.$emit('page-changed', page);
            }
        },
    },
};
</script>

<style scoped>
</style>

<template>
    <div
        class="modal"
        id="vote_viewer_modal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">
                        Vote Viewer
                    </h5>
                    <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                    ></button>
                </div>
                <div class="loader" v-if="loading"></div>
                <div class="modal-body" v-else>
                    <div v-if="showEmailList">
                        <div class="container mt-3 d-flex justify-content-between align-items-center">
                            <h6 class="mb-0">Email List for: {{ selectedPage }}</h6>
                            <button @click="showEmailList = false" class="btn btn-sm btn-secondary px-3">
                                Back
                            </button>
                        </div>

                        <BaseTable
                            title="Vote Summary"
                            :headers="['Email', 'Date']"
                            :data="voterEmails"
                        />
                    </div>

                    <div v-else>
                        <thead v-if="!voteSummary || voteSummary.length < 1">
                            <tr>
                                No data found.
                            </tr>
                        </thead>
                        <BaseTable
                            title="Vote Summary"
                            :headers="['Page Title', 'Total Votes']"
                            :data="voteSummary"
                            :actions="[
                                { label: 'View', handler: fetchVoterEmails }
                            ]"
                            v-else
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
</style>

<script>
import { Modal } from "bootstrap";
import BaseTable from './components/BaseTable.vue';

export default {
    components: {
        BaseTable,
    },
    data() {
        return {
            token: this.$store?.getters?.logged_in_user?.data?.token ?? '',
            widget_id: "",
            vote_viewer_modal: null,
            loading: true,
            voteSummary: "",
            showEmailList: false,
            selectedPage: "",
            voterEmails: [],
            widget_settings: {
                selector: "",
                show_on: "mouseover",
                position: "bottom",
            }
        };
    },
    methods: {
        async read() {
            var url =
                process.env.VUE_APP_API_URL +
                `vote-buttons/readByWidgetId/${this.$route.params.widget_id}`;
            const res = await fetch(url, {
                 headers: new Headers({
                     Authorization:
                         "Bearer " + this.$store.getters.logged_in_user.data.token,
                 }),
             });
            var data = await res.json();
            if(data){
                this.widget_settings = data;
            }
            this.fetchVoteSummary();
        },
        async fetchVoteSummary(event) {
            this.loading = true;

            let url = process.env.VUE_APP_API_URL + `widget-votes/${this.$route.params.widget_id}`;

            try {
                const res = await fetch(url, {
                    headers: new Headers({
                        Authorization: "Bearer " + this.token,
                    }),
                });

                if (!res.ok) {
                    throw new Error(`Error: ${res.status} ${res.statusText}`);
                }


                if (this.voteSummary && this.voteSummary.links) {
                    this.voteSummary.links = "";
                }

                this.voteSummary = await res.json();

            } catch (error) {
                console.error("Failed to fetch product update:", error);
            } finally {
                this.loading = false;
                if (event) {
                    event.target.disabled = false;
                }
            }
        },
        async fetchVoterEmails(item) {
            this.selectedPage = item.page;

            const url = process.env.VUE_APP_API_URL + `widget-votes/${this.$route.params.widget_id}/${this.selectedPage}`;

            try {
                const res = await fetch(url, {
                    headers: new Headers({
                        Authorization: "Bearer " + this.token,
                    }),
                });

                if (!res.ok) {
                    throw new Error(`Error: ${res.status} ${res.statusText}`);
                }

                this.voterEmails = await res.json();
                this.showEmailList = true;

            } catch (error) {
                console.error("Failed to fetch voter emails:", error);
            }
        },
        showVoteViewer() {
            var __this = this;
            __this.vote_viewer_modal.show();
            __this.read();
        }
    },
    mounted() {
        this.vote_viewer_modal = new Modal(document.getElementById("vote_viewer_modal"));
    },
};
</script>
<template>
    <!-- create modal -->
    <div
        class="modal"
        id="create_modal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
    >
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">
                        Create Onboarding Checklist Item
                    </h5>
                    <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                    ></button>
                </div>
                <div class="modal-body">
                    <input name="id" v-model="id" type="hidden" />

                    <div>Title</div>
                    <input
                        id="name"
                        name="name"
                        v-model="title"
                        type="text"
                        class="form-control mb-3"
                    />
                    <div>Description</div>
                    <textarea
                        name="description"
                        v-model="description"
                        type="text"
                        class="form-control mb-3"
                    />
                    <div>Video URL</div>
                    <input
                        id="video_url"
                        name="video_url"
                        v-model="video_url"
                        type="text"
                        class="form-control mb-3"
                    />
                    <div>Video URL button text</div>
                    <input
                        id="video_url_button_text"
                        name="video_url_button_text"
                        v-model="video_url_button_text"
                        type="text"
                        class="form-control mb-3"
                    />
                    <div>Link</div>
                    <input
                        id="link"
                        name="link"
                        v-model="link"
                        type="text"
                        class="form-control mb-3"
                    />
                    <div>Link button text</div>
                    <input
                        id="link_button_text"
                        name="link_button_text"
                        v-model="link_button_text"
                        type="text"
                        class="form-control mb-3"
                    />
                    <div>Is completed</div>
                    <input
                        id="name"
                        name="name"
                        v-model="completed"
                        true-value="1"
                        false-value="0"
                        type="checkbox"
                    />
                </div>
                <div class="modal-footer">
                    <button
                        type="button"
                        class="btn btn-secondary"
                        data-bs-dismiss="modal"
                    >
                        Close
                    </button>
                    <button
                        @click="create($event)"
                        type="button"
                        class="btn btn-primary"
                    >
                        Create
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import { Modal } from "bootstrap";

export default {
    data() {
        return {
            token: this.$store?.getters?.logged_in_user?.data?.token ?? "",
            id: "",
            title: "",
            description: "",
            video_url: "",
            video_url_button_text: "",
            link: "",
            link_button_text: "",
            completed: false,
            create_modal: null
        };
    },
    methods: {
        showCreateModal() {
            this.create_modal.show();
            document.getElementById("name").focus();
            this.resetFields();
        },
        resetFields() {
            this.id = "";
            this.title = "";
            this.description = "";
            this.video_url = "";
            this.video_url_button_text = "";
            this.link = "";
            this.link_button_text = "";
            this.completed = false;
        },
        async create(event) {
            event.target.disabled = true;
            var data = {
                title: this.title,
                description: this.description,
                video_url: this.video_url,
                video_url_button_text: this.video_url_button_text,
                link: this.link,
                link_button_text: this.link_button_text,
                completed: this.completed,
                widget_id: this.$route.params.widget_id
            };
            axios
                .post(
                    process.env.VUE_APP_API_URL + "onboarding-checklist-items",
                    data,
                    {
                        headers: {
                            Authorization: "Bearer " + this.token
                        }
                    }
                )
                .then(
                    response => {
                        if (response.data.id) {
                            this.$parent.$refs.readComponent.read();
                        }

                        event.target.disabled = false;
                        this.create_modal.hide();
                    },
                    error => {
                        console.log(error);
                    }
                );
        }
    },
    mounted() {
        this.create_modal = new Modal(document.getElementById("create_modal"));
    }
};
</script>

<template>
    <MyQuillEditor
        v-model:content="content"
        contentType="html" 
        :theme="theme"
        class="quill-editor"
    />
</template>

<script>
import { QuillEditor } from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import '@vueup/vue-quill/dist/vue-quill.bubble.css';

export default {
    name: 'QuillEditor',
    components: {
        MyQuillEditor: QuillEditor,
    },
    props: {
        modelValue: {
            type: String,
            default: '',
        },
        theme: {
            type: String,
            default: 'snow',
        },
    },
    computed: {
        content: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit('update:modelValue', value);
                this.$emit('input', value);
            },
        },
    },
};
</script>

<style>
.ql-editor {
    height: 300px;
    max-height: 400px; 
    background-color: #f1f1f1;
    color: #000000;
}

.ql-toolbar {
    background-color: #ffffff;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
}

.quill-editor {
    background-color: #ffffff;
    color: #000000;
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
}

.ql-snow.ql-toolbar .ql-formats button {
    border-radius: 3px;
    transition: all .2s ease-out;
}

.ql-snow.ql-toolbar button.ql-active {
    background-color: #21BA58;
    color: #ffffff;
}

.ql-snow.ql-toolbar button.ql-active svg > * {
    stroke: #ffffff !important;
}

.ql-snow.ql-toolbar button:hover {
    background-color: #21BA58;
    color: #ffffff;
}

.ql-snow.ql-toolbar button:hover svg > * {
    stroke: #ffffff !important;
}

.ql-snow.ql-toolbar .ql-picker-label.ql-active {
    background-color: #21BA58;
    color: #ffffff;
}

.ql-snow.ql-toolbar .ql-picker-label.ql-active svg > * {
    stroke: #ffffff !important;
}

.ql-picker-label {
    border: 5px;
    transition: all .2s ease-out;
}

.ql-snow.ql-toolbar .ql-picker-label:hover {
    background-color: #21BA58;
    color: #ffffff;
}

.ql-snow.ql-toolbar .ql-picker-label:hover svg > * {
    stroke: #ffffff !important;
}

.ql-editor.ql-blank::before {
    color: #aaaaaa;
}

.ql-editor a {
    color: #21BA58; 
}

.ql-picker-options .ql-picker-item {
    transition: all .2s ease-out;
}

.ql-picker-options .ql-picker-item:hover,
.ql-picker-options .ql-picker-item.ql-selected {
    background-color: #21BA58 !important;
    color: #ffffff !important;
}
</style>

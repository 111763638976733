<template>
    <div class="container p-5">
        <h4>
            Exit-intent Popup

        <EmbedCode className="wbnz-exit-intent-popup" widgetName="exit-intent-popup" />
        <ExitIntentPopupEmails />

        </h4>

        <br>
        <div class="row">
            <Sidebar/>
            <div class="col-md-8">
                <div id="preview">

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import EmbedCode from "../../components/EmbedCode.vue";
import Sidebar from "./components/Sidebar";
import ExitIntentPopupEmails from "./ExitIntentPopupEmails.vue";

export default {
    components: {
        Sidebar,
        EmbedCode,
        ExitIntentPopupEmails
    },
    data() {
        return {
            widget_url: process.env.VUE_APP_WIDGET_URL,
            widget_id: ""
        };
    },
    mounted(){
        this.widget_id = this.$route.params.widget_id;
    },
};
</script>
<template>
    <div class="container p-5 pt-0">
        <h4>
            Customize Quick Feedback widget
            <span class="badge rounded-pill bg-secondary">{{
                data.total
            }}</span>

        </h4>

        <EmbedCode className="wbnz_quick_feedback" widgetName="quick-feedback" />

        <br>
        
        <div class="row w-100">
            <form id="widget-customizer" class="col-md-4 widget-customizer" @input="handleInput">
                <div class="d-flex align-items-start">
                    <div class="nav flex-column nav-pills me-3" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                        <li class="nav-link active" id="v-pills-basic-tab" data-bs-toggle="pill" data-bs-target="#v-pills-basic" type="button" role="tab" aria-controls="v-pills-basic" aria-selected="false">Basic</li>
                        <li class="nav-link" id="v-pills-colors-tab" data-bs-toggle="pill" data-bs-target="#v-pills-colors" type="button" role="tab" aria-controls="v-pills-colors" aria-selected="false">Colors</li>
                        <li class="nav-link" id="v-pills-text-tab" data-bs-toggle="pill" data-bs-target="#v-pills-text" type="button" role="tab" aria-controls="v-pills-text" aria-selected="false">Text</li>
                        <li class="nav-link" id="v-pills-spacing-tab" data-bs-toggle="pill" data-bs-target="#v-pills-spacing" type="button" role="tab" aria-controls="v-pills-spacing" aria-selected="false">Spacing</li>
                    </div>

                    <div class="tab-content flex-fill" id="v-pills-tabContent">
                        <div class="tab-pane fade show active" id="v-pills-basic" role="tabpanel" aria-labelledby="v-pills-basic-tab">
                            <div class="container p-0 mb-4">
                                <div class="form-grid">
                                    <div class="form-group">
                                        <label>Name</label>
                                        <input type="text" class="form-control" v-model="widget_name" id="selector">
                                    </div>
                                    <div class="form-group">
                                        <label>Selector</label>
                                        <input type="text" class="form-control" v-model="widget_settings.selector" id="selector">
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="tab-pane fade" id="v-pills-colors" role="tabpanel" aria-labelledby="v-pills-colors-tab">
                            <div class="container p-0 mb-4">
                                <div class="form-grid">
                                    <div class="form-group">
                                        <label for="qf-tb-text-color">Text color</label>
                                        <div class="form-group">
                                            <input v-model="widget_settings.text_color" type="text" id="qf-tb-text-color" class="form-control setting setting-color">
                                            <PickrColorPicker v-model="widget_settings.text_color" @input="handleInput" />
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label for="qf-tb-bg-color">Background color</label>
                                        <div class="form-group">
                                            <input v-model="widget_settings.background_color" type="text" id="qf-tb-bg-color" class="form-control setting setting-color">
                                            <PickrColorPicker v-model="widget_settings.background_color" @input="handleInput" />
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label for="qf-tb-icon-color">Icon color</label>
                                        <div class="form-group">
                                            <input v-model="widget_settings.icon_color" type="text" id="qf-tb-icon-color" class="form-control setting setting-color">
                                            <PickrColorPicker v-model="widget_settings.icon_color" @input="handleInput" />
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label for="qf-tb-unselected-icon-color">Hover/Chosen icon color</label>
                                        <div class="form-group">
                                            <input v-model="widget_settings.unselected_icon_color" type="text" id="qf-tb-unselected-icon-color" class="form-control setting setting-color">
                                            <PickrColorPicker v-model="widget_settings.unselected_icon_color" @input="handleInput" />
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label for="qf-tb-send-btn-color">Send button color</label>
                                        <div class="form-group">
                                            <input v-model="widget_settings.send_button_color" type="text" id="qf-tb-send-btn-color" class="form-control setting setting-color">
                                            <PickrColorPicker v-model="widget_settings.send_button_color" @input="handleInput" />
                                        </div>
                                    </div>
                                    
                                    <div class="form-group">
                                        <label for="">Send button text color</label>
                                        <div class="form-group">
                                            <input v-model="widget_settings.send_button_text_color" type="text" id="qf-tb-icon-color" class="form-control setting setting-color">
                                            <PickrColorPicker v-model="widget_settings.send_button_text_color" @input="handleInput" />
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label for="qf-tb-close-btn-color">Close button color</label>
                                        <div class="form-group">
                                            <input v-model="widget_settings.close_button_color" type="text" id="qf-tb-close-btn-color" class="form-control setting setting-color">
                                            <PickrColorPicker v-model="widget_settings.close_button_color" @input="handleInput" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="tab-pane fade" id="v-pills-text" role="tabpanel" aria-labelledby="v-pills-text-tab">
                            <div class="container p-0 mb-4">
                                <div class="form-grid">
                                    <div class="form-group form-grid-span-full">
                                        <label for="">Text</label>
                                        <textarea v-model="widget_settings.text" class="form-control" name="text" id="qf-tb-text" cols="30" rows="10"></textarea>
                                    </div>
                                    <div class="form-group">
                                        <label>Text font size</label>
                                        <input type="number" class="form-control" v-model="widget_settings.text_font_size" id="text_font_size">
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="tab-pane fade" id="v-pills-spacing" role="tabpanel" aria-labelledby="v-pills-spacing-tab">
                            
                            <div class="container p-0 mb-4">
                                <div class="form-grid">
                                    <div class="form-group">
                                        <label for="">Padding</label>
                                        <input v-model="widget_settings.padding" type="number" id="qf-tb-padding" class="form-control setting">
                                    </div>
                                    <div class="form-group">
                                        <label for="">Border radius</label>
                                        <input v-model="widget_settings.border_radius" type="number" id="qf-tb-padding" class="form-control setting">
                                    </div>
                                    <div class="form-group">
                                        <label for="show-on">Show on</label>
                                        <div class="position-relative">
                                            <select class="form-control pr-5" v-model="widget_settings.show_on" id="show-on" @change="handleInput">
                                                <option value="mouseover">Hover</option>
                                                <option value="click">Click</option>
                                            </select>
                                            <span class="dropdown-icon position-absolute" style="top: 50%; right: 10px; transform: translateY(-50%);">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                                    <path d="M7.247 11.14l-4.796-5.481A.5.5 0 0 1 2.5 5h11a.5.5 0 0 1 .388.84l-4.796 5.48a.5.5 0 0 1-.776 0z"/>
                                                </svg>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label>Notify on (Slack or Google chat webhook URL)</label>
                                        <input type="text" class="form-control" v-model="widget_settings.notify_on" id="notify-on">
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="container p-0 mb-4">
                            <div class="form-grid">
                                <button type="button" class="btn btn-primary" @click="save($event)">
                                    <span v-if="isSaving" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    <span v-if="!isSaving">Save changes</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                
            </form>
            <div class="col-md-8">
                <div id="preview"></div>
            </div>
        </div>
    </div>
</template>

<style scoped>
    .widget-customizer{
        border: 1px solid #c6c6c6;
        border-radius: 8px;
        background: #000000;
        color:white;
        padding:20px 10px;
    }
    .widget-customizer .form-group{
        display:inline-flex;
    }

    .widget-customizer .nav-link{
        color: white;
        background: black;
        border-right: 1px solid #555 !important;
        border-radius:0px;
    }
    .widget-customizer .nav-link.active{
        color: white;
        background: black;
        border: 1px solid #555 !important;
        border-right: none !important;
        border-radius: 4px 0 0 4px;
    }
    .widget-customizer .container{
        display:grid;
    }
    .dropdown-icon svg {
        color: black;
    }
</style>

<script>
import axios from "axios";
import EmbedCode from "../../components/EmbedCode.vue";
import PickrColorPicker from "../../../components/PickrColorPicker.vue";

export default {
    components: {
        EmbedCode,
        PickrColorPicker
    },
    data() {
        return {
            token: this.$store?.getters?.logged_in_user?.data?.token ?? '',
            widget_url: process.env.VUE_APP_WIDGET_URL,
            widget_id: "",
            data: "",
            id: "",
            name: "",
            desciption: "",
            edit_modal: null,
            delete_modal: null,
            search_keyword: "",
            isSaving: false,
            notify_on: "",
            widget_name: "",
            widget_settings: {
                text : "How would you rate your experience?",
                text_font_size: 16,
                text_color: "#000000",
                background_color: "#FFFFFF",
                icon_color: "#000000",
                send_button_color: "#000000",
                send_button_text_color: "#FFFFFF",
                close_button_color: "#FF0000",
                unselected_icon_color: "#919191",
                padding: 10,
                border_radius: 8,
            }
        };
    },
    methods: {
        async read() {
            const widgetUrl = `${process.env.VUE_APP_API_URL}widgets/${this.$store.getters.logged_in_user.data.user.id}/${this.widget_id}`;
            const widgetSettingUrl = `${process.env.VUE_APP_API_URL}quick-feedbacks/readByWidgetId/${this.widget_id}`;
            
            const headers = new Headers({
                Authorization: "Bearer " + this.$store.getters.logged_in_user.data.token,
            });

            const [widgetResponse, widgetSettingResponse] = await Promise.all([
                fetch(widgetUrl, { headers }),
                fetch(widgetSettingUrl, { headers }),
            ]);

            const widgetData = await widgetResponse.json();
            const widgetSettingData = await widgetSettingResponse.json();

            if (widgetData) {
                this.widget_name = widgetData.data.name;
            }

            if (widgetSettingData) {
                this.widget_settings = widgetSettingData;
                this.notify_on = widgetSettingData.notify_on;
            }
        },
        handleInput() {
            const event = new CustomEvent('previewDataUpdated', { 
                detail: { 
                    previewData: this.widget_settings 
                }
            });
            window.dispatchEvent(event);
        },
        async save(event){
            var __this = this;
            __this.isSaving = true;
            event.target.disabled = true;
            var data = __this.widget_settings;
            data.widget_id = __this.widget_id;

            try {
                const response = await axios.post(
                    process.env.VUE_APP_API_URL + `quick-feedbacks/save/${__this.widget_id}`,
                    data,
                    {
                        headers: {
                            Authorization: "Bearer " + this.$store.getters.logged_in_user.data.token,
                        },
                    }
                );

                if (response.data.id) {
                    await axios.put(
                        process.env.VUE_APP_API_URL + `widgets/${this.widget_id}`,
                        { name: __this.widget_name },
                        {
                            headers: {
                                Authorization: "Bearer " + this.$store.getters.logged_in_user.data.token,
                            },
                        }
                    );
                }

                await this.$parent.$refs.readComponent.read();
                this.preview();
                this.preview();
            } catch (error) {
                console.error('Error saving widget settings or updating name:', error);
            } finally {
                event.target.disabled = false;
                __this.isSaving = false;
            }
        },
        preview (){
            let previewContainer = document.querySelector('[data-webynize-id="' + this.$route.params.widget_id + '"]');
            let widgetScript = document.querySelector('[src="' + process.env.VUE_APP_WIDGET_URL + 'quick-feedback.js' + '"]');

            if (previewContainer) {
                previewContainer.remove();
            }

            if (widgetScript) {
                widgetScript.remove();
            }
            previewContainer = document.createElement("div");
            previewContainer.setAttribute("data-webynize-id", this.$route.params.widget_id);
            previewContainer.classList.add("wbnz_quick_feedback");
            widgetScript = document.createElement("script");
            widgetScript.src = process.env.VUE_APP_WIDGET_URL + `quick-feedback.js`;
            widgetScript.defer = true;
            document.getElementById("preview").appendChild(previewContainer);
            document.getElementById("preview").appendChild(widgetScript);

            widgetScript.onload = function() {
                window.dispatchEvent(new Event('load'));
            };
        }
    },
    mounted(){
        var __this = this;
        __this.widget_id = this.$route.params.widget_id;
        __this.read();
        __this.preview();
    },
    computed: {
        textStyles() {
            var __this = this;
            return {
                "color": __this.widget_settings.text_color,
            };
        },
        containerStyles() {
            var __this = this;
            return {
                "background-color": __this.widget_settings.background_color,
                "padding": __this.widget_settings.padding + `px`,
                "border-radius": __this.widget_settings.border_radius + `px`
            };
        },
        iconStyles() {
            var __this = this;
            return {
                "color": __this.widget_settings.icon_color,
            };
        },
        closeButtonStyles() {
            var __this = this;
            return {
                "color": __this.widget_settings.close_button_color,
            };
        },
    }
};
</script>

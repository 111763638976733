<template>
    <div class="col-sm-9">
        <h4 class="d-inline-block">Change Plan</h4>

        <ComingSoon />
    </div>
</template>

<script>
import ComingSoon from "../components/ComingSoon";
export default {
    components: {
        ComingSoon,
    },
};
</script>

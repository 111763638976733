<template>
    <div id="widget-customizer" class="col-md-4 widget-customizer">
        <div class="d-flex align-items-start">
            <div class="nav flex-column nav-pills me-3" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                <li class="nav-link active" id="v-pills-basic-tab" data-bs-toggle="pill" data-bs-target="#v-pills-basic" type="button" role="tab" aria-controls="v-pills-basic" aria-selected="false">Basic</li>
                <li class="nav-link" id="v-pills-items-tab" data-bs-toggle="pill" data-bs-target="#v-pills-items" type="button" role="tab" aria-controls="v-pills-items" aria-selected="false">Items</li>
                <li class="nav-link" id="v-pills-colors-tab" data-bs-toggle="pill" data-bs-target="#v-pills-colors" type="button" role="tab" aria-controls="v-pills-colors" aria-selected="false">Colors</li>
                <li class="nav-link" id="v-pills-font-tab" data-bs-toggle="pill" data-bs-target="#v-pills-font" type="button" role="tab" aria-controls="v-pills-font" aria-selected="false">Fonts</li>
                <li class="nav-link" id="v-pills-alignment-positions-tab" data-bs-toggle="pill" data-bs-target="#v-pills-alignment-positions" type="button" role="tab" aria-controls="v-pills-alignment-positions" aria-selected="false">Alignments</li>
                <li class="nav-link" id="v-pills-spacing-tab" data-bs-toggle="pill" data-bs-target="#v-pills-spacing" type="button" role="tab" aria-controls="v-pills-spacing" aria-selected="false">Spacings</li>
            </div>

            <form class="tab-content flex-fill" id="v-pills-tabContent" @input="handleInput">
                <div class="tab-pane fade show active" id="v-pills-basic" role="tabpanel" aria-labelledby="v-pills-basic-tab">
                    <div class="container p-0 mb-4">
                        <div class="form-grid">
                            <div class="form-group">
                                <label>Name</label>
                                <input type="text" class="form-control" v-model="widget_name" id="widget_name">
                            </div>
                            <div class="form-group">
                                <label>Title Text</label>
                                <input v-model="widget_settings.title_text" type="text" id="title_text" class="form-control setting">
                            </div>
                            <div class="form-group">
                                <label>Open Link Button Text</label>
                                <input v-model="widget_settings.open_link_button_text" type="text" id="open_link_button_text" class="form-control setting">
                            </div>
                            <div class="form-group">
                                <label>Mark Done Button Text</label>
                                <input v-model="widget_settings.mark_done_button_text" type="text" id="mark_done_button_text" class="form-control setting">
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tab-pane fade" id="v-pills-items" role="tabpanel" aria-labelledby="v-pills-items-tab">
                    <div class="container p-0 mb-4">
                        <div class="form-grid mb-2">
                            <div class="form-group">
                                <button
                                    data-bs-toggle="modal"
                                    @click="parentRefs.createComponent.showCreateModal()"
                                    type="button"
                                    class="btn btn-primary me-2 float-end fs-5 d-flex align-items-center"
                                >
                                    Add Item
                                </button>
                            </div>
                            <div class="form-group">
                                <form @submit.prevent="parentRef.search(event, search_keyword)">
                                    <div class="input-group mb-3 float-end me-2">
                                        <input
                                            v-model="search_keyword"
                                            type="text"
                                            class="form-control"
                                            placeholder="Type any word..."
                                            aria-label="Search"
                                            aria-describedby="button-addon2"
                                            @input="parentRef.detectEmptySearchBox(search_keyword)"
                                        />
                                        <button
                                            class="btn btn-secondary"
                                            type="submit"
                                            id="button-addon2"
                                        >
                                            Search
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>

                        <div class="loader" v-if="!parentRef || parentRef.loading"></div>
                        <div class="table-responsive"  v-else>
                            <table class="table table-dark table-hovered">
                                <thead v-if="!parentRef.data.data || parentRef.data.data.length < 1">
                                    <tr>
                                        No data found.
                                    </tr>
                                </thead>
                                <thead v-if="parentRef.data.data && parentRef.data.data.length > 0">
                                    <tr>
                                        <th>Title</th>
                                        <th>Description</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody v-if="parentRef.data.data && parentRef.data.data.length > 0">
                                    <tr v-for="item in parentRef.data.data" :key="item.id">
                                        <td>{{ item.title }}</td>
                                        <td>{{ item.description }}</td>
                                        <td>
                                            <button
                                                data-bs-toggle="modal"
                                                @click="
                                                    parentRefs.updateComponent.showEditModal(
                                                        $event,
                                                        item
                                                    )
                                                "
                                                type="button"
                                                class="btn btn-primary me-2"
                                            >
                                                Edit
                                            </button>
    
                                            <button
                                                type="button"
                                                @click="
                                                    parentRefs.deleteComponent.showDeleteModal(
                                                        item.id
                                                    )
                                                "
                                                class="btn btn-danger me-2"
                                            >
                                                Delete
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <nav v-if="parentRef && parentRef.data.data && parentRef.data.data.length > 0" aria-label="Page navigation example">
                            <ul class="pagination">
                                <span v-for="item in parentRef.data.links" :key="item.url" class="me-1">
                                    <li :class="{ active: item.active }" class="page-item">
                                        <a
                                            v-if="item.url"
                                            @click="read(event, item.url)"
                                            class="page-link"
                                            href="#"
                                            ><span v-html="item.label"></span
                                        ></a>
                                    </li>
                                </span>
                            </ul>
                        </nav>
                    </div>
                </div>

                <div class="tab-pane fade" id="v-pills-colors" role="tabpanel" aria-labelledby="v-pills-colors-tab">
                    <div class="container p-0 mb-4">
                        <div class="form-grid">
                            <div class="form-group">
                                <label for="">Content background color</label>
                                <div class="form-group">
                                    <input v-model="widget_settings.content_background_color" type="text" id="content_background_color" class="form-control setting setting-color">
                                    <PickrColorPicker v-model="widget_settings.content_background_color" @input="handleInput" />
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="">Title text color</label>
                                <div class="form-group">
                                    <input v-model="widget_settings.title_font_color" type="text" id="title_font_color" class="form-control setting setting-color">
                                    <PickrColorPicker v-model="widget_settings.title_font_color" @input="handleInput" />
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="">List text color</label>
                                <div class="form-group">
                                    <input v-model="widget_settings.list_font_color" type="text" id="list_font_color" class="form-control setting setting-color">
                                    <PickrColorPicker v-model="widget_settings.list_font_color" @input="handleInput" />
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="">Open link background color</label>
                                <div class="form-group">
                                    <input v-model="widget_settings.open_link_button_background_color" type="text" id="open_link_button_background_color" class="form-control setting setting-color">
                                    <PickrColorPicker v-model="widget_settings.open_link_button_background_color" @input="handleInput" />
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="">Open link text color</label>
                                <div class="form-group">
                                    <input v-model="widget_settings.open_link_button_font_color" type="text" id="open_link_button_font_color" class="form-control setting setting-color">
                                    <PickrColorPicker v-model="widget_settings.open_link_button_font_color" @input="handleInput" />
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="">Mark done background color</label>
                                <div class="form-group">
                                    <input v-model="widget_settings.mark_done_button_background_color" type="text" id="mark_done_button_background_color" class="form-control setting setting-color">
                                    <PickrColorPicker v-model="widget_settings.mark_done_button_background_color" @input="handleInput" />
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="">Mark done text color</label>
                                <div class="form-group">
                                    <input v-model="widget_settings.mark_done_button_font_color" type="text" id="mark_done_button_font_color" class="form-control setting setting-color">
                                    <PickrColorPicker v-model="widget_settings.mark_done_button_font_color" @input="handleInput" />
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="">Checkbox background color</label>
                                <div class="form-group">
                                    <input v-model="widget_settings.checkbox_background_color" type="text" id="checkbox_background_color" class="form-control setting setting-color">
                                    <PickrColorPicker v-model="widget_settings.checkbox_background_color" @input="handleInput" />
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="">Popup background color</label>
                                <div class="form-group">
                                    <input v-model="widget_settings.popup_background_color" type="text" id="popup_background_color" class="form-control setting setting-color">
                                    <PickrColorPicker v-model="widget_settings.popup_background_color" @input="handleInput" />
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="">Popup text color</label>
                                <div class="form-group">
                                    <input v-model="widget_settings.popup_font_color" type="text" id="popup_font_color" class="form-control setting setting-color">
                                    <PickrColorPicker v-model="widget_settings.popup_font_color" @input="handleInput" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tab-pane fade" id="v-pills-font" role="tabpanel" aria-labelledby="v-pills-font-tab">
                    <div class="container p-0 mb-4">
                        <div class="form-grid">
                            <div class="form-group">
                                <label for="">Title font size</label>
                                <input v-model="widget_settings.title_font_size" type="number" id="title_font_size" class="form-control setting">
                            </div>
                            <div class="form-group">
                                <label for="">Open link font size</label>
                                <input v-model="widget_settings.open_link_button_font_size" type="number" id="open_link_button_font_size" class="form-control setting">
                            </div>
                            <div class="form-group">
                                <label for="">Mark done font size</label>
                                <input v-model="widget_settings.mark_done_button_font_size" type="number" id="mark_done_button_font_size" class="form-control setting">
                            </div>
                            <div class="form-group">
                                <label for="">List font size</label>
                                <input v-model="widget_settings.list_font_size" type="number" id="list_font_size" class="form-control setting">
                            </div>
                            <div class="form-group">
                                <label for="">Popup font size</label>
                                <input v-model="widget_settings.popup_font_size" type="number" id="popup_font_size" class="form-control setting">
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tab-pane fade" id="v-pills-alignment-positions" role="tabpanel" aria-labelledby="v-pills-alignment-positions-tab">
                    <div class="container p-0 mb-4">
                        <div class="form-grid">
                            <div class="form-group">
                                <label for="" >Position</label>
                                <select v-model="widget_settings.position" class="form-control setting" name="position" id="content_text_align">
                                    <option value="0">Bottom Left</option>
                                    <option value="1">Bottom Right</option>
                                    <option value="2">Top Left</option>
                                    <option value="3">Top Right</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tab-pane fade" id="v-pills-spacing" role="tabpanel" aria-labelledby="v-pills-spacing-tab">
                    <div class="container p-0 mb-4">
                        <div class="form-grid">
                            <div class="form-group">
                                <label for="">Padding</label>
                                <input v-model="widget_settings.padding" type="number" id="padding" class="form-control setting me-2">
                            </div>
                            <div class="form-group">
                                <label for="">Popup Padding</label>
                                <input v-model="widget_settings.popup_padding" type="number" id="popup_padding" class="form-control setting me-2">
                            </div>
                        </div>
                    </div>
                </div>

                <div class="container p-0 mb-4">
                    <div class="form-grid">
                        <button type="button" class="btn btn-primary" @click="save($event)">
                            <span v-if="isSaving" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            <span v-if="!isSaving">Save changes</span>
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<style scoped>
    .widget-customizer{
        border: 1px solid #c6c6c6;
        border-radius: 8px;
        background: #000000;
        color:white;
        padding:20px 10px;
    }
    .widget-customizer .form-group{
        display:inline-flex;
    }

    .widget-customizer .nav-link{
        color: white;
        background: black;
        border-right: 1px solid #555 !important;
        border-radius:0px;
    }
    .widget-customizer .nav-link.active{
        color: white;
        background: black;
        border: 1px solid #555 !important;
        border-right: none !important;
        border-radius: 4px 0 0 4px;
    }
    .widget-customizer .container{
        display:grid;
    }
</style>

<script>
import axios from "axios";
import PickrColorPicker from "../../../../components/PickrColorPicker.vue";

export default {
    props: {
        parentRef: {
            type: Object,
            default: null,
        },
        parentRefs: {
            type: Object,
            default: null,
        },
    },
    components: {
        PickrColorPicker,
    },
    data() {
        return {
            token: this.$store?.getters?.logged_in_user?.data?.token ?? '',
            widget_url: process.env.VUE_APP_WIDGET_URL,
            widget_id: "",
            data: "",
            id: "",
            search_keyword: "",
            isSaving: false,
            widget_name: "",
            widget_settings: {
                widget_id: 0,
                title_text: "Brief",
                content_background_color: "#ffffff",
                title_font_color: "#121925",
                title_font_size: 16,
                open_link_button_background_color: "#ffffff",
                open_link_button_font_color: "#121925",
                open_link_button_font_size: 16,
                open_link_button_text: "Open Link",
                mark_done_button_background_color: "#21ba58",
                mark_done_button_font_color: "#ffffff",
                mark_done_button_font_size: 16,
                mark_done_button_text: "Mark as Done",
                list_font_size: 16,
                list_font_color: "#121925",
                checkbox_background_color: "#28a745",
                popup_background_color: "#ffffff",
                popup_font_color: "#121925",
                popup_font_size: 16,
                popup_padding: 24,
                padding: 24,
                position: 0,
            }
        };
    },
    methods: {
        async read() {
            const widgetUrl = `${process.env.VUE_APP_API_URL}widgets/${this.$store.getters.logged_in_user.data.user.id}/${this.widget_id}`;
            const widgetSettingUrl = `${process.env.VUE_APP_API_URL}onboarding-checklist/readByWidgetId/${this.widget_id}`;
            
            const headers = new Headers({
                Authorization: "Bearer " + this.token,
            });

            const [widgetResponse, widgetSettingResponse] = await Promise.all([
                fetch(widgetUrl, { headers }),
                fetch(widgetSettingUrl, { headers }),
            ]);

            const widgetData = await widgetResponse.json();
            const widgetSettingData = await widgetSettingResponse.json();

            if (widgetData) {
                this.widget_name = widgetData.data.name;
            }

            if (widgetSettingData) {
                this.widget_settings = widgetSettingData;
            }
        },
        handleInput() {
            const event = new CustomEvent('previewDataUpdated', { 
                detail: { 
                    previewData: this.widget_settings 
                }
            });
            window.dispatchEvent(event);
        },
        async save(event){
            var __this = this;
            __this.isSaving = true;
            event.target.disabled = false;
            var data = __this.widget_settings;
            data.widget_id = __this.widget_id;

            try {
                const response = await axios.post(
                    process.env.VUE_APP_API_URL + `onboarding-checklist/save`,
                    data,
                    {
                        headers: {
                            Authorization: "Bearer " + this.token,
                        },
                    }
                );

                if (response.data.success) {
                    await axios.put(
                        process.env.VUE_APP_API_URL + `widgets/${this.widget_id}`,
                        { name: __this.widget_name },
                        {
                            headers: {
                                Authorization: "Bearer " + this.token,
                            },
                        }
                    );
                }

                await this.read();
            } catch (error) {
                console.error('Error saving widget settings or updating name:', error);
            } finally {
                event.target.disabled = false;
                __this.isSaving = false;
                __this.preview();
            }
        },
        allPages() {

            if (event.target.checked) {
                this.widget_settings.pages = "";
            }
        },
        preview (){
            let previewContainer = document.querySelector('[data-webynize-id="' + this.$route.params.widget_id + '"]');
            let widgetScript = document.querySelector('[src="' + process.env.VUE_APP_WIDGET_URL + 'onboarding-checklist.js' + '"]');

            if (previewContainer) {
                previewContainer.remove();
            }

            if (widgetScript) {
                widgetScript.remove();
            }
            previewContainer = document.createElement("div");
            previewContainer.setAttribute("data-webynize-id", this.$route.params.widget_id);
            previewContainer.classList.add("wbnz-onboarding-checklist");
            widgetScript = document.createElement("script");
            widgetScript.src = process.env.VUE_APP_WIDGET_URL + `onboarding-checklist.js`;
            widgetScript.defer = true;
            document.getElementById("preview").appendChild(previewContainer);
            document.getElementById("preview").appendChild(widgetScript);

            widgetScript.onload = function() {
                window.dispatchEvent(new Event('load'));
            };
        }
    },
    mounted(){
        var __this = this;
        __this.widget_id = this.$route.params.widget_id;
        __this.read();
        __this.preview();
    }
};
</script>
<template>
    <div class="container mt-3">
        <div class="table-responsive">
            <table class="table table-bordered table-hover table-striped align-middle rounded-1 overflow-hidden">
                <thead class="table-dark">
                    <tr>
                        <th v-for="header in headers" :key="header" class="py-2 px-3">{{ header }}</th>
                        <th v-if="actions.length" class="py-2 px-3 text-center">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in data" :key="index" class="transition-all">
                        <td v-for="key in Object.keys(item)" :key="key" class="py-2 px-3">{{ item[key] }}</td>
                        <td v-if="actions.length" class="py-2 px-3 text-center">
                            <button
                                v-for="(action, actionIndex) in actions"
                                :key="actionIndex"
                                @click="action.handler(item)"
                                class="btn btn-sm btn-primary px-3"
                            >
                                {{ action.label }}
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            required: true,
        },
        headers: {
            type: Array,
            required: true,
        },
        data: {
            type: Array,
            required: true,
        },
        actions: {
            type: Array,
            default: () => [],
        },
    },
};
</script>

<style scoped>
.transition-all {
    transition: all 0.2s ease;
}
</style>
